.puzzle_acceuil{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 835px;
    justify-content: end;
}
.puzzle_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
  }
  