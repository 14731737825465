@import "../../../../Style/_mixins.scss";
@import "../../../../Style/_variables.scss";

.RadioInput{
  @include font_poppins_regular;
  color: #D8D7E1;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 1em;
  border-radius: 2em;
  color: $color-light-purple;
  justify-self: self-start;
  width: fit-content;
  cursor: pointer;
  input[type="radio" ]{
    margin-right: 10px;
    accent-color: #58BC6E;
  }
}
.CustomGroupButton{
  color: $color-light-purple;
  display: flex;
  align-items: center;

  
  .group-button-content{
    vertical-align: middle;
    font-size: 16px;
    &::before{
      content: "+";
      font-size: 1.2em;
      color: $color-light-purple;
      margin-right: .5em;
  
    }
  }
  
}