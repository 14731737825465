.account {
  &_right {
      //flex-direction: column;
      margin-left: 40px;

      &_order {
          @include font_poppins_regular;
          width: 730px;
          height: auto;
          //background: $color-white;
          background: $color-grey-purple;
          box-shadow: $shadow_currency;
          border-radius: 17px;
          padding-bottom: 1%;

          &_name {
              color: $color-pure-purple;
              font-weight: 700;
              padding: 20px;
          }

          &_tab {
                p {
                    color: $color-white-grey;
                }
            
              &_header {
                width: 620px;
                height: 35px;
                background: rgba(25, 27, 38, 0.5);
                border-radius: 8px;
                color: $color-light-pink;
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                
                

                &_transactions {
                    width: 150px;
                    margin-left: 2%;
                }

                &_date {
                    width: 100px;
                    text-align: right;
                    margin-left: 1%;
                }

                &_details {
                    width: 70px;
                    text-align: right;
                    margin-left: 1%;
                }

                &_total {
                    width: 70px;
                    text-align: right;
                    margin-left: 1%;

                }
                &_status {
                    width: 70px;
                    text-align: right;
                    margin-left: 1%;
                }
              }

              &_content {
                width: 620px;
                height: auto;
                border-radius: 10px;
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                color: $color-white-grey;
                margin-top: 1%;
                cursor: pointer;
                padding-top: 1%;
              }

              &_hr {
                  width: 655%;
                  background-color: $color-light-purple;
                  height: 1px;
                  border: none;
              }
              table {
                  height: auto;
                  border-radius: 8px;
                  overflow: hidden;
              }

              &_title {
                  font-weight: 700;
                  font-size: $font-legend-info;
                  color: $color-light-purple;
                  &_start {
                    //   border-top-left-radius: 8px;
                    //   border-bottom-left-radius: 8px;
                    //   -moz-border-radius-top-left : 8px;
                  }

                  &_details {
                      padding-left: 163px;
                  }
                  &_general {
                      padding-left: 75px;
                      padding-right: 5px;
                  }

                  &_end {
                      padding-left: 75px;
                      padding-right: 10px;
                    //   border-top-right-radius: 8px;
                    //   border-bottom-right-radius: 8px;
                  }
              }

              &_content {
                  &_item {
                      &_img {
                          width: 25px;
                          height: 25px;
                          margin-right: 5px;
                      }
                  }
              }

              &_header {
                  width: 620px;
                  height: 35px;
                  background: rgba(25, 27, 38, 0.5);
              }
          }
          &_more {
              text-align: center;
              
              margin-right: 10px;
              margin-top: 5px;
              
              &_text {
                  @include font_poppins_regular;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 12px;
                  text-decoration: underline;
                  color: $color-pure-purple;
                  margin: 10px;
                  cursor: pointer;
              }
            }

            &_switch {
                width: 96px;
                height: 30px;
                background: $background-dark;
                border-radius: 10px;

                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                margin-right: 5%;
                margin-top: 2%;

                &_bought {
                    color: $color-white;
                    width: 48px;
                }

                &_sold {
                    color: rgba(105, 106, 115, 1);
                    width: 48px;
                    cursor: pointer;
                }
            }
        }

    }
}

.btn_cancel_order {
    @include font_poppins_regular;
    width: 90px;
    height: 26px;
    border-radius: 6px;
    background: $background-dark;

    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 14px;
    color: $color-white;
    cursor: pointer;
    margin-right: 2%;
    margin-top: 2%;
    margin-bottom: 2%;
    align-self: flex-end;
    margin-left: 2%;

    &_img {
        margin-right: 2%;
        width: 14px;
        height: 14px;
    }
    
}

.cancel_order_bg {
    background: rgba(52, 55, 77, 1);
}

