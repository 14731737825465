.mypuzzles {
   
    padding-top: 140px;
    &_content {
        background: #2A2D3E;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        width: 1000px;
        padding : 40px;

        &_tab {
            @include font_poppins_regular;
            &_section {

            }
            &_item {
                display : flex;
                &_content {
                    display : flex;
                    margin-right: 30px;
                    position:relative;
                    &_text {

                    }
                    &_line-select {
                        
                        position: absolute;
                        height: 2px;
                        z-index: 4;
                        top: 155.9%;
                        background :  rgba(155, 142, 255, 1);
                        &_purchased {
                            width: 112%;
                            
                        }
                        &_sold {
                            width: 142%;  
                        }
                        &_favorite {
                            width: 129%;  
                        }
                    }
                }
                &_inactive{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: rgba(227, 222, 255, 1);
                    cursor : pointer;
                }
                &_active{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: rgba(155, 142, 255, 1);
                    cursor : pointer;
                    position : relative;
                }
            }
            &_second {
                display : flex;
                
                &_line {
                    background: #262323;
                    border-radius: 2px;
                    height: 1px;
                    margin-top: 11px;
                    width: 90%;
                }
                &_button {
                    display : flex;
                    &_btn {
                        background: linear-gradient(292.53deg,#9381ff 31.56%,#9381ff 97.92%);
                        border: none;
                        border-radius: 10px;
                        cursor : pointer;
                        
                        box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
                        color: #f9edff;
                        @include font_poppins_regular;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        height: 41px;
                        line-height: 21px;
                        margin-top: -11px;
                        width: 160px;
                    }
                }
            }
        }
        &_puzzles {
            display : flex;
            flex-wrap: wrap;
            margin-top : 20px;
            margin-left : 3%;
        }
    }
    &_product {
        /*background: #2A2D3E;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        width: 1000px;
        padding : 40px;*/
        display : flex;
        &_all-price {
            width : 100%;
            margin-top: 50px;

            &_price {
                width : 50%;
            }
            &_miles {
                width  :40%;
                margin-left: 9%;
            }
        }
        &_first {
            @include font_poppins_regular;
          width : 255px;
          padding: 60px 0px 0px 60px;
          border-radius: 40px 0 0 40px;
          background: linear-gradient(239.55deg, #433B7D 12.01%, rgba(67, 59, 125, 0.21) 103.18%);
        justify-content: space-between;
          &_img {
              margin-left : 7%;
          }
        }
        &_second {
            width : 675px;
            padding: 60px;
            background: rgba(42, 45, 62, 1);
            box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
            border-radius: 0 40px 40px 0;
            display : flex;
            &_product {
               display : flex;
               justify-content : space-between; 
               @include font_poppins_regular;
               &_information {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 17px;
                color: rgba(227, 222, 255, 1);
               }
               &_step {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 17px;
                color: rgba(76, 82, 112, 1);
                @include font_poppins_regular;

               }
            }
            &_all-seller {
                width : 100%;
                justify-content:space-between;
                @include font_poppins_regular;
                &_link {
                    margin-top : 70px;
                    width : 42%;
                    &_input {
                        margin-top: 20px;
                        width : 100%;
                        &_content {
                            background: rgba(33, 35, 50, 1);
                            box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
                            border-radius: 10px;
                            border : none;
                            width : 96%;
                            height : 45px;
                            padding-left: 4%;

                            font-style: normal;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: rgba(216, 215, 225, 1);

                            &::placeholder {
                                color: rgba(134, 132, 157, 1);
                            }
                        }
                    }
                }
            }
            &_seller{
                margin-top : 70px;
                width : 50%;
                &_text {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: rgba(216, 215, 225, 1);
                    @include font_poppins_regular;

                }
                &_group {
                    width  : 100%;
                    //border  : 1px solid red;
                    justify-content: space-between;
                    margin-top  : 20px;
                    cursor : pointer;
                    :hover {
                        background: rgba(23, 25, 35, 1);
                    }
                    &_item {
                        align-items: center;
                        background: #212332;
                        border-radius: 30px;
                        box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
                        height: 52px;
                        justify-content: center;
                        
                        &_select {
                            border: 0.6px solid #d8d7e1;
                            border-radius: 28.2px;
                            height: 12px;
                            width: 12px;
                            
                        }
                        &_selected {
                            background : green;
                        }
                        &_text-all {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            @include font_poppins_regular;
                            margin-left: 6%;
                        }
                        &_text-inactive {
                            color: rgba(134, 132, 157, 1);

                        }
                        &_text-active {
                            
                            color: rgba(216, 215, 225, 1);
                            
                        }
                    }
                    &_first {
                        width: 45%;
                    }
                    &_second {
                        width: 50%;
                    }
                }
            }
            &_title {
                margin-top  : 50px;
                &_input {
                    width : 98%;
                    margin-top : 20px;
                    &_description {
                        height : 104px;
                    }
                    
                    &_title {
                        height: 52px;
                    }
                    &_content {
                        width : 100%;
                        
                        background: rgba(33, 35, 50, 1);
                        box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
                        border-radius: 10px;
                        border : none;
                        @include font_poppins_regular;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: #d8d7e1;
                        padding-left : 2%;
                        
                        &::placeholder {
                            color: rgba(134, 132, 157, 1);   
                        }
                    }
                }
            }
            &_button {
                @include font_poppins_regular;
                width: 100%;
                //border: 1px solid red;
                justify-content: space-between;
                justify-content: flex-end;
                margin-top: 70px;    

                &_quit {
                    width : 25%;
                    box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;
                    height: 47px;
                    background: rgba(23, 25, 35, 1);
                    border : none;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: rgba(249, 237, 255, 1);
                    margin-right : 3%;
                    cursor : pointer;

                }
                &_next {
                    height: 47px;
                    background: linear-gradient(292.53deg, #9381FF 31.56%, rgba(147, 129, 255, 0.75) 97.92%);

                    box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;
                    background: linear-gradient(292.53deg, #9381FF 31.56%, rgba(147, 129, 255, 0.75) 97.92%);
                    border : none;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                    color: rgba(249, 237, 255, 1);
                    width : 22%;
                    cursor : pointer;

                }
            }
            &_price{
                &_currency {
                    @include font_poppins_regular;
                    margin-top: 20px;
                    width  : 100%;
                    //border : 1px solid red;
                    background: rgba(33, 35, 50, 1);
                    height: 52px;
                    //height :40px;
                    //padding  : 2%;
                    box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
                    border-radius: 10px;
                    padding-right: 2%;
                    padding-left: 1%;
                    
                    &_all-wallet {
                        //background: rgba(23, 25, 35, 1);
                        height: 172px;
                        /* border: 27px; */
                        border-radius: 20px;
                        position : relative;
                        background: #212332;
                        
                    }
                    &_money {
                        width : 45%;
                        margin-right: 1%;
                        
                       
                        &_img {
                            width : 20px;
                            height : 20px;
                            //margin-left: 10%;
                            margin-right: 10%;
                            margin-left: 7%;
                        }
                        &_select {
                            width : 20px;
                            height : 20px;
                            cursor : pointer;
                        }
                        &_text {
                            @include font_poppins_regular;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: rgba(216, 215, 225, 1);
                            width : 46%;
                            cursor : pointer;
                        }
                        &_content {
                            width : 100%;
                            align-items: center;
                           // justify-content: space-around;
                            margin-top  : 11%;
                        }
                    }
                    &_line {
                        width : 0.5%;
                        background: rgba(42, 45, 62, 1);

                    }
                    &_input {
                        width : 52%;
                        //border : 1px solid red;
                        &_content {
                            width : 99%;
                            height : 100%;
                            margin-left: 1%;
                            background : none;
                            border : none;
                            text-align: right;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba(134, 132, 157, 1);

                        }
                    }
                }
            }
        }
    }
    &_media {
        &_all-btn {
            margin-top : 70px;
            //border : 1px solid red;
            width : 70%;
            &_pics {
                align-items: center;
                //justify-content : center;
                margin-left : 2%;
                background: #212332;
                border-radius: 10px;
                height: 40px;
                width: 112%;
               &_input {

               } 
               &_text {
                @include font_poppins_regular;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: rgba(216, 215, 225, 1);

               }
            }
        }
        &_no-media {
            margin-top: 156px;
            margin-bottom: 156px;
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            @include font_poppins_regular;
            color: rgba(76, 82, 112, 1);

        }
        &_uploaded-img {
            @include font_poppins_regular;
            margin-top : 40px;
            
            &_text {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                color: rgba(216, 215, 225, 1);

            }
            &_all {
                margin-top  : 31px;
                width : 100%;
                flex-wrap: wrap;
                &_content {
                position : relative;
                width : 30%;
                height : 130px;
                margin-right: 3%;
                margin-bottom: 20px;
                }
                &_item {
                   width :100%;
                    filter: drop-shadow(0px 7px 15px rgba(0, 0, 0, 0.5));
                    border-radius: 10px;
                    
                   
                }
                &_close {
                    position: absolute;
                    left: 90%;
                    top: -10%;
                }
            }
        }
    }
    
    &_specification {
      &_optional {
        color: #4c5270;
      }  
      &_specs {
         
          margin-top : 70px;
          &_title {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: rgba(216, 215, 225, 1);
            @include font_poppins_regular;
          }
      }
      &_file-link {
        margin-top: 50px;
        &_all-btn {
            width  : 100%;
            margin-top : 20px;
            &_file {
                align-items: center;
                //justify-content : center;
                margin-left : 2%;
                background: #212332;
                border-radius: 10px;
                height: 40px;
                //width: 17%;
                margin-left : 3%;
                &_text {
                @include font_poppins_regular;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: rgba(216, 215, 225, 1);
                }
                &_elem {
                   width : 112%
                }
                &_link {
                   width : 17%; 
                }
            }
        }
      }
      &_no-link {
          @include font_poppins_regular;
            margin-top :40px;
            margin-bottom: 40px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: rgba(76, 82, 112, 1);
      }
      &_upload-file-container {
          position : relative;
      }
      &_upload-file {
          width : 50%;
          background: #212332;
        border-radius: 10px;
        height : 52px;
        margin-top : 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        @include font_poppins_regular;
        color: rgba(216, 215, 225, 1);
        align-items: center;

        &_icon {
            margin-right: 2%;
            margin-left : 2%;
        }
        &_close {
            position : absolute;
            left: 46%;
            top: 27%;
        }
      }
      &_link-input-container {
          position : relative;
      }
      &_link-input {
          width: 50%;
          margin-top : 25px;
          &_input {
            width : 100%;
            height: 52px;
            border-radius: 10px;
            background: rgba(33, 35, 50, 1);
            border : none;
            color: rgba(216, 215, 225, 1);
            @include font_poppins_regular;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            padding-left : 9%;
          }
          &_close {
            position : absolute;
            left: 46%;
            top: 16%;
          }
          &_icon {
            position: absolute;
            width: 25px;
            height: 25px;
            margin-left: 1%;
            margin-top: 2%;
          }
      }
    }
    &_confirmation {
        &_content {
        margin-top : 70px; 
        width : 100%;
        //height: 344px;
        background: rgba(33, 35, 50, 1);
        box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        
        &_all {
            margin : 30px;
            &_down {
                justify-content: space-between;   
                margin-top : 43px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: rgba(216, 215, 225, 1);
                @include font_poppins_regular;

              }
              &_first {
                  justify-content: space-between;
                  align-items: center;
                  &_left {
                      align-items: center;
                      @include font_poppins_regular;
                      font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                    color: rgba(216, 215, 225, 1);

                    &_img {
                        width: 40px;
                        height: 40px;
                        border-radius: 8px;
                        margin-right: 3%;
                    }

                  }
                  &_right {
                      align-items: center;
                      font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 36px;
                    color: rgba(255, 255, 255, 1);
                    @include font_poppins_regular;

                  }
              }
              
        }
        
        }
        &_btn {
            width : 30%;
            margin-top : 70px;
            margin-left : 70%;
            &_content {
              width : 100%;
              background: linear-gradient(292.53deg, #9381FF 31.56%, rgba(147, 129, 255, 0.75) 97.92%);
              /* btn */

              box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
              border-radius: 10px;
              height: 47px;
              border : none;
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 27px;
              color: rgba(249, 237, 255, 1);
              @include font_poppins_regular;
              cursor : pointer;
            }
        }
    }
}
.all-puzzle {
    margin-top: 44px;
}