@mixin font_poppins_regular {
    font-family: Poppins-Regular;
}

@mixin font_nunito_regular {
    font-family: Nunito-Regular;
}

@mixin font_anton_regular {
    font-family: Anton-Regular;
}

@mixin font_montserrat_regular {
    font-family: Montserrat-Regular;
}

@mixin font_inriaserif_regular {
    font-family: InriaSerif-Regular;
}

@mixin detail {
    width: 230px;
    height: 80px;
    background: $color-borders;
    box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin-right: 6%;
}