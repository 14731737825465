.comments {
  max-height: 500px;
  overflow: auto;
  overflow-x: hidden;
}
.comments::-webkit-scrollbar {
  width: 8px;
}

.comments::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 4px;
}

.comments::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.comments::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.between {
  display: flex;
  flex-direction: row;
  color: #f5f5f5;
  margin-top: 20px;
  margin-left: 50px;
  width: 7cm;
  border-radius: 5px;
  justify-content: space-between;
}
.time-remain {
  padding-top: 4px;
  padding-left: 4px;
  font-size: small;
}

.time-remain.value {
  font-size: 14px;
  font-weight: 700;
}
