.puzzle-user {
    &_content {
        border-radius: 20px;
        width: 1060px;
        padding: 40px;
        @include font_poppins_regular;
        margin-top: -90px;
        &_left {
            background: #2A2D3E;
            width: 35%;
            /* border: 1px solid red; */
            height: 400px;
            border-radius: 20px;
            margin-right: 5%;
            padding : 20px;
            &_title {
                color: #e3deff;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                margin-top: 7px;
            }
            &_img {
                margin-top: 16px;
                &_content {
                border-radius: 20px;
                height: 301px;
                width: 100%;
                }
            }
            &_btn {
                margin-top: 13px;
                background: rgba(33, 36, 50, 1);
                border-radius: 16px;
                width: 47%;
                /* padding: 7px; */
                text-align: center;
                padding-top: 3px;
                padding-bottom: 3px;
                margin-left: 27%;
                margin-top: 4px;
                &_text{
                    color: #e3deff;
                    font-style: normal;
                    font-size: 15px;
                    line-height: 27px;
                }
            }
        }
        &_right {
            width : 60%;
            
            &_details {
            background: #2A2D3E;
            height: 305px;
            border-radius: 20px;
            margin-bottom: 5%;
            padding : 20px;
            &_title {
                margin-top  : 7px;
                padding-left: 2%;
            }
            &_all-text {
                color: #e3deff;
                font-style: normal;
                font-size: 15px;
                line-height: 27px;
            }
            &_line {
                height: 0.1px;
                border: none;
                background-color: black;
                margin-top: 17px;
                margin-bottom: 17px;
                margin-left: 2%;
            }
            &_piece {
                justify-content: space-between;
                padding-left: 2%;
            }
            &_all-item {
                width: 100%;
                justify-content: space-between;
                //margin-top: 16px;
                margin-top: 45px;

                &_first {
                    width: 30%;
                    background: rgba(33, 36, 50, 1);
                    height: 110px;
                    justify-content: space-around;
                    padding-left: 2%;
                    border-radius: 7px;
                }
                /*&_second {
                    width : 30%;

                }*/
            }
            &_all-btn {
                width  : 100%;
                justify-content: space-between;
                padding-top : 30px;
                &_miles {
                    width : 32%;
                    //background : rgba(68, 69, 71, 0.334);
                    background: linear-gradient(292.53deg, #9381FF 31.56%, #9381FF 97.92%);
                    border-radius: 10px;
                    cursor : pointer;
                }
                &_pieces {
                    width : 32%;
                    //background: rgba(33, 36, 50, 1);
                    background: linear-gradient(292.53deg, #9381FF 31.56%, #9381FF 97.92%);
                    border : none;
                    box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;
                    cursor : pointer;
                }
                &_missing {
                    width : 32%;
                    background: linear-gradient(292.53deg, #9381FF 31.56%, #9381FF 97.92%);
                    box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
                    border-radius: 10px;
                    cursor : pointer;
                }
                &_text {
                    color: #e3deff;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 27px;
                    margin-top: 7px;
                    border: none;
                    /* text-align: center; */
                    justify-content: center;
                    align-items: center;
                    height: 41px;

                    
                }
            }
            }
            &_historic {
            background: #2A2D3E;
            
            height: 260px;
            border-radius: 20px;
            padding : 20px;
            &_tab {
                width : 96%;
                background: rgba(33, 36, 50, 1);
                border-radius: 10px;
                height : 41px;
                margin-top: 20px;
                padding-left: 2%;
                padding-right: 2%;
                align-items: center;

                &_text-column {
                    color: #e3deff;
                    font-style: normal;
                    font-size: 13px;
                    line-height: 20px;
                }
                &_order {
                    width: 40%;
                    //border: 1px solid red;
                }
                &_date {
                   width :  26%;
                   //border: 1px solid red;
                   text-align: end;
                }
                &_value {
                    width :  17%;
                    //border: 1px solid red;
                    text-align: end;
                 }
            }
            &_item {
                width : 96%;
                padding-left: 2%;
                padding-right: 2%;
                padding-top: 23px;
                &_text {
                    color: #e3deff;
                    font-style: normal;
                    font-size: 11px;
                    line-height: 20px;
                }
            }
            }
        }
    }

    &_popup-miles {
        @include font_poppins_regular;
        /*width: 400px;
        //box-shadow: 0px 10px 71px rgb(0, 0, 0,0.9);
        background: #212332;
        border-radius: 20px;
        position: absolute;
        transform: translate(-215px, 50px);
        height : 70px;*/

        
        width: 320px;
        //height: 288px;
        box-shadow: 0px 19px 72px #000000;
        border-radius: 20px;
        //background: #212332;
        background : #2A2D3E;
        position: absolute;
        z-index: 10;
        align-self: center;
        //top: 30%;
        transform: translate(-88px, 186px);

        &_content {
            margin : 25px;
            @include font_poppins_regular;

            &_title {
                @include font_poppins_regular;
                color: #e3deff;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 27px;
                
                border: none;

                height: 41px;
            }
            &_date-start {
                margin-top : 7px;

                &_text {
                    color: #e3deff;
                    font-style: normal;
                    font-size: 15px;
                    line-height: 27px;
                }
                &_input {

                    &_content {
                        background: #212332;
                        border: none;
                        border-radius: 10px;
                        color: #d8d7e1;
                        font-family: Poppins-Regular;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        height: 38px;
                        line-height: 20px;
                        padding-left: 2%;
                        padding-right: 5%;
                        text-align: right;
                        width: 93%;
                        margin-top  : 5px;
                    }
                }
            }
            &_confirm {
                &_btn {
                    color: #e3deff;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    margin-top: 25px;
    border: none;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    height: 41px;
    width : 38%;
    background: linear-gradient(292.53deg, #9381FF 31.56%, #9381FF 97.92%);
    border: none;
    //box-shadow: 0px 9px 23px rgb(0 0 0 / 15%);
    border-radius: 10px;
    cursor: pointer;
                }
            }
        }
    }
}