@import "../../../../Style/_mixins";
@import "../../../../Style/_variables.scss";

.FAQItem {
  @include font_poppins_regular;
  box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.2);
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 2em;
  background-color: rgba(0, 0, 0, 0.2);

  .faq-item-header {
    display: flex;
    justify-content: space-between;

    .item-title {
      color: $color-white-grey;
    }

    .tag-list {
      display: flex;
      gap: 1em;
      align-self: center;
    }
  }

  .users-content {
    color: $color-white-grey;
    margin-bottom: 20px;

    .users-name {
      margin: 1em 0;

      span {
        font-weight: bold;
      }
    }

    .users-answer {
      width: 80%;
      line-height: 28px;
    }
  }

  .users-info {
    color: $color-white-grey;
    display: flex;
    justify-content: space-between;

    .users-profile {
      display: flex;
      gap: 1em;
      align-items: center;

      .profile-pic {
        // padding: 2em;
        width: 3em;
        height: 3em;
        background-image: url(../../../../Assets/img/pp.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border-radius: 50%;
      }

      .profile-info {
        display: flex;
        flex-direction: column;
        gap: 0.3em;

        .profile-name {
          font-weight: bold;
        }

        .post-time {
          opacity: 0.7;
        }
      }
    }

    .cta-buttons {
      display: flex;
      justify-content: center;
      align-self: center;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      gap: 1em;
      position: relative;

      * {
        border-radius: 2em;
        background-color: rgba(27, 29, 41, 1);
        padding: 0.9em 1.5em;
        cursor: pointer;
      }

      .like-length::before {
        content: url(../../../../Assets/vectors/faqs/love-filled.svg);
        vertical-align: middle;
        margin-right: 10px;

      }
      // .unlike {
      //   width: 50px;
      //   height: 50px;
      //   background-image: url(../../../../Assets/vectors/faqs/love-outlined.svg);
      //   background-repeat: no-repeat;
      //   background-size: 100%;
      //   vertical-align: middle;
      //   margin-right: 10px;
      // }
    
      // .like {
      //   width: 50px;
      //   height: 50px;
      //   background-image: url(../../../../Assets/vectors/faqs/love-filled.svg);
      //   background-repeat: no-repeat;
      //   background-size: 100%;
      //   vertical-align: middle;
      //   margin-right: 10px;
      // }

      .see-answer::before {
        content: url(../../../../Assets/vectors/faqs/chat.svg);
        vertical-align: middle;
        margin-right: 10px;
      }

      .reply::before {
        content: url(../../../../Assets/vectors/faqs/reply.svg);
        vertical-align: middle;
        margin-right: 10px;
      }

      .delete::before {
        content: url(../../../../Assets/vectors/faqs/can-delete-remove.svg);
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
}

.tag-content {
  @include font_poppins_regular;
  color: white;
  width: fit-content;
  height: fit-content;
  padding: 0.4em 1.3em;
  border-radius: 1em;
}