.navbar {
  box-shadow: $shadow_navbar;
  @include font_poppins_regular;
  width: 100%;
  z-index: 20;
  gap: 20px;
  position: fixed;

  &_burger {
    &_menu {
      display: none;
    }
  }

  &_light {
    background-color: $color-background;
  }

  &_dark {
    //background-color: $background-color-navbar;
    background-color: rgb(21, 23, 33);
  }

  &_content {
    &_logo {
      padding: 3px;

      img {
        width: 100px;
        height: 40px;
      }
    }
  }

  &_scrool_detected {
    padding: 15px;
  }

  &_scrool_not_detected {
    padding: 25px;
  }

  &_search {
    margin-left: 250px;
    height: 44px;
    width: 250px;

    &_input::placeholder {
      color: $color-white_puzzle;
    }

    &_input:focus {
      outline: none;
    }

    &_input {
      @include font_nunito_regular;
      color: $color-white_puzzle;
      box-shadow: $input_shadow;
      width: 100%;
      height: 44px;
      padding-left: 50px;
      padding-right: 50px;
      border-radius: 38px;
      border: none;
      text-align: left;
      background: $color-borders !important;
    }

    &_img {
      position: relative;
      width: 20px;
      height: 20px;
      margin-right: -39px;
      margin-bottom: -6px;

    }

    &_popup {
      @include font_poppins_regular;
      position: absolute;
      width: 400px;
      height: 486px;
      background: $color-borders;
      box-shadow: 0px 10px 71px rgba(0, 0, 0, 0.9);
      border-radius: 20px;
      top: 87%;
      right: 43%;

      &_categories {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: rgba(65, 70, 96, 1);
        margin-left: 5%;
        margin-top: 5%;
      }

      &_delete {
        margin-left: -15%;
        margin-bottom: -3%;
        cursor: pointer;
      }
    }
  }

  &_text {
    height: 45px;
    margin-left: 46px;
    gap: 25px;
    
    
    &_content {
      color: $color-white_puzzle;
      @include font_poppins_regular;
      cursor: pointer;
      text-align: center;
      
      // align-self: center;
      width: 100px;
      height: 30px;
      // margin-right: 25px;
      margin-top: 5px;

      padding-top: 5px;
      &_font {
      }
    }

    &_content:hover {
      //background: rgba(128, 0, 255, 1);
      background: rgba(155, 142, 255, 1);
      color: $color-white;
      border-radius: 10px;
    }

    &_notify {
      //background: $color-white;
      background-color: $color-black;
      width: 35px;
      height: 35px;
      margin-top: 5px;
      border-radius: 35px;
      cursor: pointer;

      img {
        width: 15px;
        height: 20px;
        padding: 8px;
      }
    }
  }

  &_button {
    align-items: center;
    width: 154px;
    height: 36px;
    border: 1px solid $color-light-pink;
    box-sizing: border-box;
    filter: drop-shadow(0px 6px 11px rgba(0, 0, 0, 0.8));
    border-radius: 25px;
    margin-top: 0.2%;
    margin-left: 1%;
    cursor: pointer;

    &_text {
      @include font_poppins_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 15px;
      color: $color-light-pink;
    }
  }
}

.popup {
  z-index: 1;
  position: absolute;
  //background-color: $color-white;
  background-color: $background-dark;
  box-shadow: $shadow_popup;
  border-radius: 10px;
  width: 167px;
  // TODO : COMMENT TO REMOVE
  //height: 188px;
  // TODO : REMOVE THE LINE
  height: 220px;
  transform: translate(131%, 25%);

  &:after {
    content: "";
    border-width: 10px 10px 10px 0;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0) $background-dark rgba(255, 255, 255, 0) rgba(255, 0, 255, 0);
    position: absolute;
    top: -15px;
    left: 48%;
    transform: rotate(90deg);
  }

  &_content {
    height: 100%;
    width: 100%;

    &_item {
      height: 45px;
      align-items: center;

      &_left {
        width: 20%;

        img {
          width: 20px;
          height: 25px;
        }
      }

      &_right {
        width: 80%;
      }

      &_text {
        width: 43px;
        height: 15px;
        color: $color-text-popup;
        font-size: $font-body-2;
        margin-left: 13px;
      }
    }

    &_item:hover {
      background: $color-pure-purple;
    }

  }
}

.popup-settings {
  z-index: 1;
  position: absolute;
  //background-color: $color-white;
  background-color: $background-dark;
  box-shadow: $shadow_popup;
  //border-radius: 10px;
  width: 167px;
  // TODO : COMMENT TO REMOVE
  //height: 188px;
  // TODO : REMOVE THE LINE
  height: 191px;
  transform: translate(31%, 37%);
  border-radius: 10px 0 0 10px;
}

.sign_in_popup {
  height: 544px;
  //right: 15.7%;
  //top: 88.5%;
  transform: translate(160%, 11%);
}

.welcome_popup {
  height: 192px;
  transform: translate(190%, 34%);
  //right: 12.7%;
  //top: 88.5%;
}

.connection {
  z-index: 1;
  position: absolute;
  width: 305px;
  background: $background-color-light-dark;
  box-shadow: $shadow_popup;
  border-radius: 20px;
  backdrop-filter: blur(113px);
  /*right: 12.7%;
  top: 9.5%;*/

  &:after {
    content: "";
    border-width: 10px 10px 10px 0;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0) $background-dark rgba(255, 255, 255, 0) rgba(255, 0, 255, 0);
    position: absolute;
    top: -15px;
    left: 48%;
    transform: rotate(90deg);
  }

  &_logo {
    align-items: center;
    margin-top: 90%;

    &_error {
      color: $color-red-error;
      width: 202px;
      height: 18px;
      @include font_poppins_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 18px;
      padding-top: 10px;
    }

    &_img {
      width: 108px;
      height: 80px;
      margin-right: 10%;
    }

    &_text {
      @include font_poppins_regular;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: $color-light-pink;
      margin-top: 1%;
      margin-left: 1%;
    }

    &_content {
      width: 188px;
      height: 18px;
      @include font_poppins_regular;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 18px;
      color: $color-white-grey;
    }
  }

  &_form {
    @include font_poppins_regular;
    margin-top: 20%;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 18px;
    color: $color-light-pink;
    align-items: center;

    &_input {
      width: 245px;
      height: 35px;
      border-radius: 8px;
      background: $background-color-light-black;
      margin-top: 1%;
      border: none;
      padding-left: 3%;
      color: $color-white-grey;
    }

    &_error {
      border: 1px solid #ff7492;
      box-sizing: border-box;
    }

    &_input::placeholder {
      font-weight: 600;
      color: $color-grey-placeholder;
      font-size: 10px;
      line-height: 18px;
    }

    &_text {
      margin-top: 4%;
      align-self: flex-start;
      margin-left: 11%;
    }

    &_hide {
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: relative;
      //left: 35%;
      margin-left: 82%;
      bottom: 28px;
    }

    &_footer {
      color: $color-pure-purple;
      cursor: pointer;
    }
  }

  &_footer {
    @include font_poppins_regular;
    margin-top: 10%;
    align-items: center;

    &_btn {
      align-items: center;
      width: 109px;
      height: 41px;
      box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      background: $gradient_btn_confirm;
      color: rgba(249, 237, 255, 1);
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      cursor: pointer;
    }

    &_text {
      margin-top: 4%;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $color-white-grey;

      span {
        color: $color-pure-purple;
        cursor: pointer;
      }
    }
  }
}

.scrool_detected {
  height: 70px;
}

.scrool_not_detected {
  height: 90px;
}


.active_width {
  width: 100%;
}

//TABLET AND SMARTPHONE VERSION
@media (max-width: 1180px) {
  .search_puzzle {
    width: 230px !important;
  }

  .navbar {
    &_content {
      display: flex;
      justify-content: flex-start;

    }

    &_search {
      display: none;

      &_menu {
        margin-left: auto;
        margin-right: auto;
        width: 240px;
        margin-bottom: 10px;
      }

      &_popup {
        top: auto;
        width: 250px;
        left: 46%;
        right: auto;
        transform: translate(-50%);
      }
    }

    &_text {
      display: none;

      &_content:hover,
      &_notify:hover {
        background-color: transparent !important;
      }

      &_content,
      &_notify {
        margin: auto;
        padding-right: 130px;
        margin-top: 20px;
        display: flex;
        justify-content: left;

        &_icon {
          width: 9px;
          padding-left: 12px;
        }

        &_font {
          text-align: left;
          padding-top: 12px;
          padding-left: 15px;
        }
      }
    }

    &_burger {
      display: flex;
      justify-content: center;
      width: 100px;
      height: 44px;
      float: right;
      position: absolute;
      right: 0px;

      &_button {
        width: 25px;
        margin-top: 10px;
        background-image: url("../../../Assets/vectors/navbar_burger.svg");
        background-repeat: no-repeat;
        cursor: pointer;

        &_close {
          width: 25px;
          margin-top: 10px;
          background-image: url("../../../Assets/vectors/navbar_burger_close.svg");
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }

      &_menu {
        transition: width ease .3s;
        display: block;
        width: 100%;
        height: 100vh;
        position: absolute;
        margin-top: -12px;
        background-color: rgb(21, 23, 33);
        //background-color: $background-color-navbar;
        box-shadow: $shadow_navbar;
        padding-bottom: 50px;

        &_content {
          a {
            width: 150px;
            display: block;
            margin: auto;
            padding-right: 130px;
          }
        }
      }
    }
  }

  .popup {
    margin: auto;
    position: relative;
    z-index: auto;
    background-color: transparent;
    box-shadow: none;
    border-radius: none;
    transform: none;

    &_content {
      display: grid;
      justify-content: center;

      &_item:hover {
        background-color: transparent !important;
      }

      &_item {
        margin-left: 17px;

        &_text {
          color: $color-white_puzzle;
        }
      }
    }
  }

  .popup::after {
    display: none;
  }

  .navbar_text_content:hover,
  .popup_content_item:hover {
    background: #9b8eff;
    border-radius: 10px;
    color: $color-white_puzzle;
  }

  .navbar_text_content,
  .popup_content_item {
    height: 45px;
    width: 150px;
    font-size: $font-body-2;
  }
}

.notifications_number {
  @include font_poppins_regular;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: $color-white;
  position: absolute;
  //margin-left: 412px;
  //margin-top: -5px;
  transform: translate(406px, -3px);

  &_img {
    position: absolute;
    //margin-left: 21%;
    //margin-top: -5px;
    transform: translate(394px, -4px);
  }
}

.activeMenu {
  background: rgba(155, 142, 255, 1);
  color: $color-white;
  border-radius: 10px;
  padding: .1em;
}