@import "../../../Style/_mixins.scss";
@import "../../../Style/_variables.scss";

.button {
  align-items: center;
  background: dimgray;
  border-radius: 30px;
  color:white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.3em 0.7em;
}
.button.green {
  background: green;
}

.button.red {
  background: red;
}
