.account-right-historic {

    @include font_poppins_regular;
    width: 730px;
    height: auto;
    //background: $color-white;
    background: $color-grey-purple;
    box-shadow: $shadow_currency;
    border-radius: 17px;
    padding-bottom: 1%;
    margin-top : 31px;

    &_tab {
        p {
            color: $color-white-grey;
        }

        &_header {
            width: 620px;
            height: 35px;
            background: rgba(25, 27, 38, 0.5);
            border-radius: 8px;
            color: $color-light-pink;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;

            &_currency {
                width: 113px;
                margin-left: 2%;
            }
            &_amount {
                width: 159px;
                text-align: right;
            }
        }
    }

    &_elems {
        
        &_row {
            //width: 620px;
            height: 35px;
            
            border-radius: 8px;
            color: #e3deff;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;

            &_currency {
                margin-left: 9.5%;
                width: 113px;
            }
            &_amount {
                width: 157px;
                //text-align: right;
            }
        }
    }
}