@import "../../../../Style/mixins";
@import "../../../../Style/variables";

.btns{
  padding: 1em 1.5em;
  border-radius: .5em;
  cursor: pointer;
  align-self: center;
  &.cancel{
    background-color: #1B1D29;

  }
  &.confirm{
    background-color: #9997A7;
    &:hover{
      background-color: $color-pure-purple;
    }
  }
}