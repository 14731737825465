.links {
  height: auto;
  padding-bottom: 2%;
  &_body {
    margin-top: 40px;
    height: auto;
    &_left {
      @include font_poppins_regular;
      margin-right: 20px;
      &_user {
        width: 360px;
        height: 310px;
        background: $background_profile;
        border-radius: 20px;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);

        &_img {
          width: 200px;
          height: 175px;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: $color-white;
        }

        &_stats {
          &_stat {
            color: $color-light-pink;
            margin: 40%;
            &_number {
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 25px;
            }

            &_text {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }

      &_contacts {
        margin-top: 5%;
        width: 360px;
        height: auto;
        box-shadow: 0px 2px 33px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        background: $background-color-dark;

        &_requests {
          margin-top: 5%;
          &_title {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: $color-light-pink;
            margin-bottom: 20px;

            span {
              color: rgba(76, 82, 112, 1);
              margin-left: 2%;
            }

            p {
              margin-right: 2%;
            }
          }

          &_content {
            width: 300px;
            height: 72px;
            background: #34374d;
            border-radius: 15px;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $color-white-grey;

            &_text {
              margin-right: 25%;
            }

            &_accept {
              margin-right: 2%;
            }
          }
        }

        &_mylinks {
          margin-top: 10%;

          &_content {
            width: 300px;
            height: 72px;
            background: transparent;
            border-radius: 15px;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $color-white-grey;
          }
        }
      }
    }

    &_right {
      margin-left: 20px;
      &_link {
        margin-bottom: 5%;
        width: 720px;
        height: auto;
        background: $background-color-dark;
        box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
        border-radius: 15px;
        padding: 30px;

        &_header {
          &_text {
            @include font_poppins_regular;
            margin-left: 1%;
            &_name {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              color: $color-white-grey;
            }

            &_date {
              font-style: normal;
              font-weight: 300;
              font-size: 10px;
              line-height: 16px;
              color: $color-white-grey;
              opacity: 0.5;
            }
          }
        }

        &_content {
          margin-left: 10%;
          margin-top: 3%;
          &_text {
            @include font_nunito_regular;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: $color-white;

            margin-bottom: 1%;
          }

          &_puzzle {
            width: 516px;
            height: 61px;
            box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.08);
            border-radius: 5px;
            background: $background-dark;

            &_img {
              border-radius : 10px;
              width: 83px;
            }

            &_text {
              @include font_poppins_regular;
              margin-left: 3%;
              &_class {
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 18px;
                opacity: 0.6;
                color: $color-white-grey;
              }

              &_title {
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                color: $color-white-grey;
              }
            }
          }

          &_big_img {
            margin-right: 1%;
            margin-left: -20%;
          }

          &_small_img {
            margin-bottom: 5%;
          }
        }

        &_footer {
          margin-top: 3%;
          margin-left: 8%;
        }

        &_comments {
          @include font_poppins_regular;
          align-self: center;
          width: 516px;
          height: auto;
          box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.2);
          border-radius: 15px;
          background: $background-dark;
          padding: 20px;
          margin-top: 1%;

          &_input {
            width: 426px;
            height: 40px;
            border-radius: 10px;
            background: $color-borders;
            border: none;
            padding-left: 2%;
            color: #d8d7e1;
          }

          &_input::placeholder {
            @include font_poppins_regular;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: rgba(103, 101, 121, 1);
          }

          &_btn {
            position: relative;
            width: 34px;
            height: 34px;
            align-self: end;
            margin-bottom: 3%;
            margin-left: -40px;
          }

          &_comment {
            margin-left: 2%;
            margin-top: 1%;
            align-items: center;
            &_content {
              color: $color-white-grey;
              margin-left: 2%;
              &_header {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                span {
                  @include font_montserrat_regular;
                  font-style: normal;
                  font-weight: 300;
                  font-size: 10px;
                  line-height: 12px;
                }
              }

              &_text {
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 18px;
                opacity: 0.8;
              }
            }
          }

          &_more {
            align-self: center;
            color: $color-pure-purple;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            text-decoration: underline;

            cursor: pointer;
            margin-top: 2%;
          }
        }
      }
    }
  }
}

.likes_number {
  @include font_poppins_regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: $color-light-pink;
}

.cursor {
  cursor: pointer;
}
