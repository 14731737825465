@import "../../../Style/_mixins.scss";
@import "../../../Style/_variables.scss";

.Footer {
  background-color: #161823;
  width: 100%;

  .new {
    // @include font_poppins_regular;
    // display: flex;
    // align-items: center;
    // justify-content: space-around;
    // margin: 0 100px 0 100px;
    // font-size: 18px;
    // font-weight: bold;

    // color: $color-text-wallets;
    // padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 1.3em;
    @include font_poppins_regular;
    color: $color-text-wallets;
    .new-wrapper{
      
    
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 100px 0 100px;
    font-size: 18px;
    font-weight: bold;

    
    padding: 20px 0;

    }
    

    .iconic {
      background-image: url(../../../Assets/img/Iconic.png);
      background-repeat: no-repeat;
      background-size: contain;
      width: 70px;
      height: 30px;
    }
    .copyright{
      align-self: center;
      margin-bottom: 10px;
      font-size: small;
    }
  }

  .old {
    align-items: center;
    color: $color-text-wallets;
    cursor: pointer;
    background-color: $background-color-navbar;
    display: flex;
    gap: 20px;
    font-size: $font-date;
    font-style: italic;
    font-weight: bold;
    height: 50px;
    justify-content: center;
    line-height: normal;
    width: 100%;

    & a {
      color: $color-text-wallets;
    }
  }
}

.ButtonFooter {
  font-size: .8em;
  display: flex;
  align-items: center;
  gap: 1em;
  line-height: 1;
  background-color: #1B1D29;
  padding: .8em 1.5em;
  border-radius: 60px;
  cursor: pointer;
  

  .icon{
    background-repeat: none;
    background-size: contain;
    width: 1.5em;
    height: 1.5em;
    &.faq { background-image: url(../../../Assets/img/faq.svg) }
    &.contact {background-image: url(../../../Assets/img/call-out.svg)}
    &.about {background-image: url(../../../Assets/img/puzzle-about.svg) }
    &.policy {background-image: url(../../../Assets/img/privacy-tips.svg);}
    &.term {background-image: url(../../../Assets/img/term-of-use.svg); }
  }
}