@import "../../../../Style/mixins";
@import "../../../../Style/_variables.scss";

.privacy {
  @include font_poppins_regular;

  .option-wrapper {
    display: flex;
    //justify-content: space-between;
    flex-direction: column;
    align-items: center;
    background-color: #212332;
    padding: 1.5em;
    // margin-bottom: 10px;
    border-radius: 0.8em;
    // position: relative;

    .items {
      color: #d8d7e1;
      font-weight: bold;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .item-value {
        color: #86849d;
        display: flex;
        align-self: center;
        align-items: center;
        gap: 0.5em;
        cursor: pointer;
      }

      .arrow {
        width: 15px;
        height: 15px;
        background-image: url(../../../../Assets/vectors/details/arrow-open-end.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }

      .side {
        display: flex;
        align-self: center;
        gap: 0.5em;
      }
    }

    .item-detail {
      color: #86849d;
      align-self: center;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked {
          + {
            .slider {
              background-color: #2196f3;

              &:before {
                transform: translateX(26px);
              }
            }
          }
        }

        &:focus {
          + {
            .slider {
              box-shadow: 0 0 1px #2196f3;
            }
          }
        }
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;

        &:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: 0.4s;
        }
      }

      .round {
        border-radius: 34px;

        &:before {
          border-radius: 50%;
        }
      }
    }

    .choices {
      display: flex;
      flex-direction: column;
      width: 100%;

      gap: 0.5em;
      // position:relative;

      .choice-title {
        margin: 20px 0;
        color: #86849d;
      }

      .choices-list {
        display: flex;

        justify-content: space-between;
        align-items: center;

        .choice-content {
          display: flex;
          gap: 1em;
          align-self: center;
        }
      }
      .choices-listSetting {
        display: flex;
        padding-top: 10px;
        justify-content: space-between;
        align-items: center;

        .choice-content {
          // display: flex;
          // gap: 1em;
          // align-self: center;
          width: 100%;
        }
      }
    }
  }

  .option-title {
    margin: 20px 0 30px 0;
    color: #86849d;
  }

  .save-button {
    @include font_poppins_regular;
    margin-left: 5px;
    margin-top: 3px;
    font-size: 15px;
    background-color: $color-pure-grey;
    color: #f9edff;
    border: none;
    border-radius: 0.5em;
    cursor: pointer;

    &:hover {
      background-color: #9381ff;
    }
  }

  .reset-container {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
  }

  .block-user {
    @include font_poppins_regular;
    font-size: 18px;
    padding: 0.9em 1em;
    background-color: #1c1e2b;
    border-radius: 2em;
    color: #86849d;
    cursor: pointer;

    &::before {
      content: "+";
      margin-right: 10px;
      font-size: 20px;
      font-weight: bolder;
    }
  }

  // olld one
  &_container {
    &_content {
      width: 935px;
      //height: 700px;
      background: #2a2d3e;
      //box-shadow: 0px 32px 33px -25px rgb(0 0 0 / 12%);
      border-radius: 17px;
      padding-bottom: 1%;
      margin-top: 30px;
      padding: 42px;

      &_item-container {
      }

      &_item {
        margin-bottom: 25px;
        width: 78%;

        &_text {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #d8d7e1;
        }

        &_select {
          width: 100%;
          //justify-content: space-between;
          margin-top: 20px;
          cursor: pointer;
          //border : 1px solid red;

          &_elem {
            align-items: center;
            background: #212332;
            border-radius: 30px;
            //box-shadow: 0px 9px 23px rgb(0 0 0 / 15%);
            height: 45px;
            justify-content: center;
          }

          &_public {
            width: 15%;
          }

          &_private {
            width: 15%;
            margin-left: 2%;
          }

          &_s-private {
            width: 20%;
            margin-left: 2%;
          }

          &_selected {
            border: 0.6px solid #d8d7e1;
            border-radius: 28.2px;
            height: 12px;
            width: 12px;
          }

          &_active {
            background: green;
          }

          &_all-text {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            margin-left: 6%;
            color: #d8d7e1;
          }
        }
      }

      &_custom-group {
        position: relative;

        &_input {
          margin-top: 20px;

          &_content {
            background: #212332;
            border: none;
            border-radius: 10px;
            //box-shadow: 0 9px 23px rgb(0 0 0 / 15%);
            color: #d8d7e1;
            //font-family: Poppins-Regular;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            padding-left: 2%;
            width: 100%;
            height: 38px;
          }
        }
      }

      &_validate {
        width: 100%;
        //margin-left: 88%;

        &_content {
          background: linear-gradient(
            292.53deg,
            #9381ff 31.56%,
            #9381ff 97.92%
          );
          border: none;
          border-radius: 10px;
          cursor: pointer;
          //box-shadow: 0px 9px 23px rgb(0 0 0 / 15%);
          color: #f9edff;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          height: 35px;
          line-height: 21px;
          //width: 160px;
          text-align: center;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.custom-group-popup {
  @include font_poppins_regular;
  position: absolute;
  width: 300px;
  height: 181px;
  //background: #2a2d3e;
  //box-shadow: 0px 10px 71px rgb(0 0 0 / 90%);
  background: #151721;
  border-radius: 20px;
  top: 75%;
  right: -26%;

  &_user {
    width: 360px;
    height: 60px;
    border-radius: 10px;
    background: transparent;
    cursor: pointer;
    margin-bottom: 2%;
    margin-top: 1%;

    &_username {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #d8d7e1;
      width: 207px;
    }

    &_check {
    }
  }
}

.validate {
  margin-left: 82%;
}

.restore {
  margin-left: 2%;
}
