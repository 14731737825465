@import "../../../../../Style/mixins";
@import "../../../../../Style/variables";

.Unblock {
  @include font_poppins_regular;
  color: #D6D1F1;
  text-align: center;

  .unblock-label {
    margin-bottom: 50px;
  }

  .decision-buttons {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
}