@import "../../../Style/_mixins.scss";
@import "../../../Style/_variables.scss";

.button {
  @include font_poppins_regular;
  align-items: center;
  background: $gradient_btn_confirm;
  border-radius: 10px;
  box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
  color: rgba(249, 237, 255, 1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  padding: 1em 1.5em;
}
