.label-puzzle{
    color: white;
    border-radius: 15px;
    padding: 2px 2px 2px 2px;
    background:linear-gradient(to bottom right, rgb(186, 117, 241), rgb(243, 152, 235));
    width: 50%;
}
.price-owned{
    margin-left: 55px !important;
    margin-right: -55px !important;
}