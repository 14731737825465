html,
body,
#root,
.app {
  margin: 0;
  padding: 0;
  line-height: 1.3;
  //background: $background-dark;
  //overflow: hidden;
  min-height: 100vh;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

ul,
li {
  list-style-type: none;
}

.hidden {
  display: none !important;
}

.not_visible {
  visibility: hidden;
}

//flexbox
.inline {
  display: inline-flex;
}
.flex-elem {
  display: flex;
}

.hidden{
  display: none !important;
}

.flex {
  display: flex;
  justify-content: center;

  &-js-sb {
    justify-content: space-between;
  }

  &-js-se {
    justify-content: space-evenly;
  }

  &-js-sa {
    justify-content: space-around;
  }

  &-js-c {
    justify-content: center;
  }

  &-js-fs {
    justify-content: flex-start;
  }

  &-js-fe {
    justify-content: flex-end;
  }

  &-ai-c {
    align-items: center;
  }

  &-ai-fs {
    align-items: flex-start;
  }

  &-ai-fe {
    align-items: flex-end;
  }

  &-fd-c {
    flex-direction: column;
  }

  &-fd-r {
    flex-direction: row;
  }

  &-fw-w {
    flex-wrap: wrap;
  }

  &-fw-nw {
    flex-wrap: nowrap;
  }
}

.dark {
  background: $background-dark;
}
.light {
  background: $color-background;
}
.color_white {
  color: $color-white;
}
.color-black {
  color: $color-black;
}
.background_item_light {
  background: $color-white;
}
.background_item_dark {
  background: $color-borders;
}
.color_text_nft {
  color: $color-text-nft;
}
.color_lighter_purple {
  color: $color-lighter-purple;
}
.color_pink {
  color: $color-pink;
}

.background_lighter_purple {
  background-color: $color-lighter-purple;
}
.background_pink {
  background-color: $color-pink;
}

.color_blue {
  color: $color-blue;
}
.color_text_wallets {
  color: $color-text-wallets;
}
.color-text-header {
  color: $color-text-header;
}
.color_gray {
  color: $color-gray-order-item;
}
.color_blue_lighter {
  color: $color-blue-ligther;
}
.color_blue_details {
  color: $color-blue-dark-details;
}
.color_path {
  color: $color-path;
}
.color_dark_nav {
  background: $color-dark-nav;
}
.navbar_dark {
  background: $color-dark-nav;
}
.navbar_light {
  background: $color-background;
}
.background_search_white {
  input {
    background: $color-white;
  }
}
.background_search_black {
  input {
    background: $color-black;
  }
}
.color_purple {
  color: $color-pure-purple !important;
}
.color_pink {
  color: $color-pink;
}


input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.cursor_pointer {
  cursor: pointer;
}

input{
  width: 100%;
  box-sizing: border-box !important;
}