.notifications {
    @include font_poppins_regular;
    width: 400px;
    //height: 518px;
    box-shadow: 0px 10px 71px rgba(0, 0, 0, 0.9);
    background: $background-dark;
    border-radius: 20px;
    position: absolute;
    //margin-top: 3%;
    //margin-left: -11%;
    transform: translate(-215px, 50px);

    &_header {
        margin-top: 5%;
        &_title {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            color: $color-white-grey;
            margin-left: 5%;
        }

        &_button {
            width: 120px;
            height: 30px;
            border-radius: 20px;
            background: $color-borders;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 18px;
            color: $color-white;
            margin-right: 5%;
            cursor: pointer;
        }

        &_button:hover {
            background: rgba(24, 26, 36, 1);
        }
    }

    &_today {

        &_menu {
            width: 56px;
            height: 25px;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            border-radius: 19px;
            background: $color-borders;
            margin-left: 73%;
            z-index: 5;
            position: absolute;
            margin-top: -3%;

            img {
                cursor: pointer;
            }
        }
        &_title {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: rgba(65, 70, 96, 1);
            margin-left: 5%;
            margin-top: 5%;
            margin-bottom: 5%;
        }

        &_content {
            margin-left: 5%;
            width: 340px;
            height: 74px;
            border-radius: 10px;
            margin-bottom: 5%;
            z-index: 2;
            &_middle {
                width : 78%;
            }
            &_img {
                margin-right: 5%;
                margin-bottom: 7%;
                margin-left: 2%;
            }

            &_read {
                margin-top: 3%;
            }

            &_text {

                &_title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 18px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    color: $color-white-grey;
                    opacity: 0.6;
                }   

                &_main {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: $color-white-grey;
                }
            }
        }

        
    }
}

.notification_hover_bg {
    background-color: rgba(24, 26, 36, 1);
}

.read_width {
    width: 25px;
    margin-left: 80%;

}
//TABLET AND SMARTPHONE VERSION
@media (max-width: 1180px) {
    .notifications {
        width : 250px;
        transform: translate(-1px, 7px);
        
        &_today {
            &_content {
                width : 230px;
                margin-left : 4%;
               
                &_text {
                    &_main {
                        font-size: 9px;
                        line-height: 15px;
                    }
                    &_title {
                        font-size: 7px;
                        line-height: 15px;
                    }
                }
                &_img {
                    margin-left: 4%;
                }   
            } 
            &_title {
                margin-left : 4%;
            }
        }
        &_number {
            transform: translate(36px, -4px);
            &_img {
                transform: translate(23px, -4px); 
            }
        }
        &_header {
            &_title {
                font-size : 14px;
                margin-left: 4%;
            }
            &_button {
                width : 105px;
                font-size: 9px;
                margin-right: 4%;
            }
        }
    }
}
