.row{
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px;
}
.row .col-md-4{
    width: 25%;
    margin-bottom: 40px;
}
.my-swiper:before{
    padding-bottom: 0!important;
}
.market_puzzle_img{
    object-fit: cover;
}
.search_container{
    margin-bottom: 30px;
    margin-left: 0;
    width: 100%;
}
.home_section3_body_card_view_cover {
    margin-top: -64px;
}