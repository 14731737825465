.privacy {
  padding: 4% 5%;
  //margin-top: 100px;
}

p {
  line-height: 20px;
}

h1, h2, h3, h4 {
  line-height: 12px;
}

.h-privacy {
  font-size: 25px;
}
.p-date {
  font-size: 15px;
  margin: 0px;
}

.p-content {
  font-size: 18px;
  margin: 0px;
  line-height: 25px;
}


.h-privacy-two {
  font-size: 20px;
}

.h-privacy-three {
  font-size: 18px;
}

.ls-primary {
  font-size: 16px;
  .ls-content {
      margin: 0px;
      padding: 0px;
      font-size: 16px;
      line-height: 25px;
  }
}
.Privacy{
  flex: 1;

}
