.contact {
  width: inherit;
  input{
    margin-bottom: 1em;

  }
  .submit-btn{
    display: flex;
    justify-content: flex-end;
  }
  
}
