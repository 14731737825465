.register {
  width: 65%;
  &_header {
    margin-top: 5%;

    &_title {
      @include font_montserrat_regular;
      color: $color-light-pink;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      
    }

    &_path {
      @include font_poppins_regular;
      color: $color-light-pink;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      margin-top: 1%;
      margin-bottom: 50px;
      span:last-child {
        font-weight: bold;
      }

      &_gt {
        margin-bottom: -1%;
        font-size: 25px;
        margin-left: 0.5%;
        margin-right: 0.5%;
      }
    }
  }

  &_logo {
    align-items: center;
    margin-top: 5%;

    &_error {
      //width: 134px;
      height: 18px;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      color: $color-red-error;
      padding-top: 30px;
    }
    &_img {
      width: 108px;
      height: 80px;
    }
    &_text {
      @include font_poppins_regular;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: $color-light-pink;
      margin-top: 1%;
      margin-left: 1%;
    }
  }

  &_form {
    @include font_poppins_regular;
    margin-top: 4%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $color-light-pink;
    align-items: center;
    &_input {
      width: 320px;
      height: 50px;
      border-radius: 8px;
      background: $background-color-light-black;
      margin-top: 1%;
      //border: none;
      padding-left: 1%;
      color: $color-white-grey;
    }

    &_error {
      border: 1px solid #ff7492;
      box-sizing: border-box;
    }
    &_normal {
      border: none;
    }

    &_input::placeholder {
      font-weight: 600;
      color: $color-grey-placeholder;
    }

    &_text {
      margin-top: 1%;
      //width: 28%;
      width: 300px;
    }

    &_checkbox {
      &_input {
        margin-top: 8%;
        margin-right: 2%;
        margin-left: -8%;
        margin-bottom: -1%;
        height: 16px;
        width: 16px;
        appearance: none;
        border: 1px solid $color-pure-purple;
        border-radius: 3px;
      }

      &_input:checked {
        margin-right: 2%;
        margin-left: -8%;
        margin-bottom: -1%;
        height: 16px;
        width: 16px;
        appearance: none;
        border: 1px solid $color-pure-purple;
        border-radius: 3px;
        background: $color-pure-purple;
      }
      a {
        color: $color-pure-purple;
      }
    }

    &_hide {
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: relative;
      //left: 13%;
      margin-left: 300px;
      bottom: 16%;
    }
  }

  &_footer {
    @include font_poppins_regular;
    margin-top: 4%;
    align-items: center;

    &_btn {
      align-items: center;
      width: 216px;
      height: 41px;
      box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      background: $color-borders;
      color: rgba(249, 237, 255, 1);
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      cursor: pointer;
      border: none;
    }

    &_active {
      background: rgba(147, 129, 255, 1);
    }
    &_disable {
      background: rgba(42, 45, 62, 1);
    }

    &_text {
      margin-top: 1%;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $color-white-grey;
      span {
        color: $color-pure-purple;
      }
    }
  }
}
