.account {
    &_right {
        &_statements {
            @include font_poppins_regular;
            width: 730px;
            height: 305px;
            //background: $color-white;
            background: $color-grey-purple;
            box-shadow: $shadow_currency;
            border-radius: 17px;
            margin-top: 40px;

            &_name {
                color: $color-pure-purple;
                font-weight: 700;
                padding: 20px;
            }

            &_tab {
                &_table {
                    
                }

                &_hr {
                    width: 655%;
                    background-color: $color-light-purple;
                    height: 1px;
                    border: none;
                }
                table {
                    height: 155px;
                }

                &_title {
                    font-weight: 700;
                    font-size: $font-legend-info;
                    color: $color-light-purple;
                    &_start {
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                        padding-right: 45px;
                    }

                    &_details {
                        padding-left: 163px;
                    }
                    &_general {
                        padding-left: 260px;
                        padding-right: 5px;
                    }

                    &_end {
                        padding-left: 160px;
                        padding-right: 10px;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }

                &_content {
                    &_item {
                        font-size: $font-legend-info;
                        //color: $color-text-nft;
                        color: $color-white;
                        text-align: end;
                        margin: 5px;
                        span {
                            margin: 5px;
                        }

                        &_img {
                            width: 25px;
                            height: 25px;
                            margin-right: 5px;
                        }
                    }
                }

                &_header {
                    width: 620px;
                    height: 35px;
                    background: rgba(25, 27, 38, 0.5);
                    padding-left: 1%;
                }
            }
        }
    }
}
