@import "~react-image-gallery/styles/scss/image-gallery.scss";

.home {
  height: 1297px;
  position: relative;
  &_section3 {
    margin-top: 2%;
    &_puzzles {
      width: 720px;
      margin-left: 30px;
      flex-wrap: wrap;
      &_img {
        width: 152px;
        height: 128px;
        margin-left: 20px;
        padding-top: 20px;
      }

      &_content {
        flex-direction: column;

        &_text {
          @include font_poppins_regular;
          width: 130px;
          height: 20px;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 19px;
          color: $color-light-grey;
          margin-left: 32px;
          padding-top: 3px;
        }

        &_size {
          width: 35px;
          height: 35px;
          background: $background-color-black;
          backdrop-filter: blur(3px);
          border-radius: 10px;
          position: absolute;
          margin-left: 130px;
          margin-top: 100px;
        }

        &_like {
          width: 20px;
          //position: absolute;
          z-index: 2;
          padding-top: 8px;
          margin-left: 8px;
        }
      }
    }

    &_body {
      width: 713px;
      height: 480px;
      &_card {
        padding: 10px;
        margin-bottom: 5%;
        &_view {
          &_img {
            width: 210px;
            height: 210px;
            border-radius: 20px;
          }

          &_cover {
            background-color: rgba(137, 137, 137, 0.1);
            backdrop-filter: blur(45px);
            filter: blur(2px);
            position: relative;
            z-index: 1;
            margin-top: -27%;
            margin-left: 5px;
            height: 54px;
            width: 96%;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
          }
          &_title {
            @include font_poppins_regular;
            font-size: $font-body-3;
            color: $color-white_puzzle;
            text-shadow: $light_shadow;
            text-align: start;
            font-weight: 700;
            margin-left: 8%;
            position: relative;
            z-index: 2;
            margin-top: -22%;
          }
          &_category {
            @include font_poppins_regular;
            font-size: $font-legend-info;
            color: $color-white_puzzle;
            font-style: italic;
            text-shadow: $light_shadow;
            position: relative;
            z-index: 2;
            margin-left: 18px;
            margin-top: 2px;
          }
        }
      }
    }
    &_links {
      flex-direction: column;
      margin-left: 10px;
      align-items: center;

      &_show {
        width: 315px;
        //border: 5px solid red;
        background: $background-color-dark;
        box-shadow: $div_shadow;
        border-radius: 10px;
        margin-bottom: 10px;
        padding-top: 10px;
        padding-left: 8px;

        &_img {
          width: 35px;
          height: 35px;
          border: 1px solid $background-color-white;
          box-sizing: border-box;
          border-radius: 35px;
        }

        &_text {
          @include font_poppins_regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          width: 207px;
          color: rgb(202, 202, 214);
          margin-left: 5px;

          &_description {
            margin-left: 5px;
          }
          &_temps {
            font-weight: normal;
            font-size: 10px;
            line-height: 16px;
            opacity: 0.5;
            color: $color-dark-grey;
          }

          &_footer {
            margin-left: -15%;
            margin-bottom: 5%;

            &_likes_img {
              margin-right: 2%;
            }

            &_likes_text {
              margin-right: 2%;
            }

            &_comments_img {
              margin-right: 2%;
            }

            &_comments_text {
              margin-right: 2%;
            }
          }
        }

        &_content {
          width: 267px;
          height: 61px;
          box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.08);
          border-radius: 5px;
          background: $background-color-light-dark;
          margin-top: 10%;
          margin-bottom: 10%;
          margin-left: -10%;

          &_img {
            width: 70px;
            height: 61px;
            margin-right: 5%;
          }

          &_text {
            font-style: normal;
            font-weight: normal;
            line-height: 18px;
            &_category {
              @include font_poppins_regular;
              font-size: 10px;
              text-transform: uppercase;
              opacity: 0.6;
            }

            &_title {
              font-size: 13px;
            }
          }
        }
      }
      &_text {
        @include font_montserrat_regular;
        align-self: flex-start;
        color: $color-light-pink;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        height: 27px;
        padding-bottom: 10px;
      }

      &_button {
        @include font_poppins_regular;
        width: auto;
        height: 41px;
        background: $color-borders;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: $shadow_confirm;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $color-pure-purple;
        margin-top: 4%;
        align-items: center;
        padding: 0px 20px 0px 20px;
      }
    }
  }
  &_container {
    &_point {
      &_carousel {
        font-size: 50px;
        line-height: 2px;
        height: 30px;
        margin-bottom: 5%;
        &_1 {
          cursor: pointer;
          color: $color-dark-white;
          margin-left: 2px;
          margin-right: 2px;
        }
        &_2 {
          cursor: pointer;
          color: $color-dark-white;
          margin-left: 2px;
          margin-right: 2px;
        }
        &_3 {
          cursor: pointer;
          color: $color-dark-white;
          margin-left: 2px;
          margin-right: 2px;
        }
      }
    }
  }
  &_section2 {
    height: 384px;
    //border: 5px solid red;
    width: 1139px;
    //background: url(../../Assets/img/home_carousel_bg.svg) no-repeat;
    border-radius: 30px;

    &_title {
      @include font_poppins_regular;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 40px;
      color: $color-light-pink;
      margin: 3% 1%;
      margin-top: 110px;
    }

    &_first {
      margin-bottom: -50px;
      &_text {
        color: $background-color-white;
        width: 500px;
        text-align: left;
        margin-left: -70px;
        

        &_header {
          @include font_poppins_regular;
          color: $color-pure-white;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
        }

        &_content {
          padding-top: 20px;
        }

        &_description {
          @include font_inriaserif_regular;
          color: $background-color-white;
          width: 409px;
          height: 93px;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 46px;
        }

        &_example {
          padding-top: 20px;

          &_border {
            width: 36px;
            border-top: 2px solid $color-light_black;
            margin-right: 10px;
          }

          &_text {
            color: $color-pure-white;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            width: 257px;
            height: 31px;
          }
        }
      }

      &_img {
        margin-left: 140px;
        margin-top: 100px;
        &_img {
          width: 235px;
          height: 267px;
        }

        &_text {
          flex-direction: column;
          text-align: left;
          margin-left: -45px;

          &_name {
            color: $background-color-white;
            width: 77px;
            height: 20px;
            font-style: italic;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
          }
          &_quantity {
            width: 28px;
            height: 12px;
            font-style: normal;
            font-weight: normal;
            font-size: 9px;
            line-height: 12px;
            color: $color-red-white;
            padding-top: 5px;
          }
          &_img {
            width: 30px;
            height: 5px;
            margin-top: 5%;
          }
          &_description {
            width: 62px;
            height: 12px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            color: $color-red-white !important;
            padding-top: 10px;
          }
          &_price {
            width: 77px;
            height: 21px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $background-color-white;
            padding-top: 5px;
          }
          &_buttons {
            width: 45px;
            height: 20px;
            border-radius: 10px;
            border: 0.5px solid $color-dark-red;
            box-sizing: border-box;
            backdrop-filter: blur(51px);
            margin-top: 20%;
            cursor: pointer;
          }
        }
      }
    }

    &_second {
      margin-left: 80px;
      margin-bottom: 100px;
      &_btn {
        margin-left: 270px;
        background: $background-gradient-red;
        border-radius: 10px;
        border: none;
        width: 217px;
        height: 40px;
        margin-top: 15px;
        cursor: pointer;

        &_text {
          color: $background-color-white;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          width: 190px;
          height: 21px;
        }
      }

      &_share {
        margin-left: 10px;
      }

      &_icon {
        width: 39px;
        height: 43px;
        background: $color-dark-red;
        border: 1px solid $color-dark-red;
        box-sizing: border-box;
        border-radius: 38px;

        &_img {
          align-items: center;
          width: 40px;
          height: 33px;
          padding-top: 4px;
          cursor: pointer;
        }
        &_share {
          align-items: center;
          width: 30px;
          height: 20px;
          padding-top: 9px;
          margin-left: 3px;
          cursor: pointer;
        }
      }
    }
  }
}

.liked {
  background: $backgroud-color-white-opac !important;
}

.title_puzzles {
  margin-left: 15%;
}

.button-margin {
  margin-top: 0;
}

.button-width {
  width: 202px;
  margin-top: 2%;
}

//CAROUSEL
.image-gallery-description {
  background: transparent !important;
  bottom: 35px !important;
  height: inherit;
  width: inherit;
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: cover;
  min-height: 389px;
  border-radius: 50px;

}

@media (min-width: 50px) and (max-width: 1180px) {
  .home {
    height: auto;

    &_container {
      &_point {
        &_carousel {
          margin-bottom: 25%;
        }
      }
    }

    &_section2 {
      &_title {
        margin: 120px 0% 4% 3%;
      }
      &_first {
        margin-bottom: -60px;
        &_img {
          margin-left: -320px;
          margin-top: 300px;
        }
        &_text {
          margin-left: -650px;
        }
      }
      &_second {
        margin-left: 20px;
        margin-bottom: 170px;

        &_btn {
          margin-left: 40px;
        }
      }
    }
    &_section3 {
      display: block;

      &_links {
        display: block;


        &_text {
          padding-left: 30px;
        }

        &_show {
          width: auto;
          margin-top: 20px;
          margin-left: 30px;
          margin-right: 30px;

          &_content {
            width: 100%;
            margin-top: 2%;
            margin-bottom: 0%;
            margin-left: -4%;
          }

          &_img {
            margin-left: 10px;
            margin-top: 10px;
          }

          &_text {
            margin-top: 10px;
            width: 100%;
            &_footer {
              margin-left: 0%;
              margin-bottom: 2%;
              margin-top: 2%;
            }
          }
        }

        &_button {
          width: 200px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 50px;
        }
      }
      &_puzzle {
        display: block;
      }
      &_puzzles {
        width: auto;
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }

  .title_puzzles {
    margin-left: 0%;
  }
  .link_market {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  .image-gallery {
    margin-bottom: 100px;
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (min-width: 50px) and (max-width: 600px) {
  .home {
    &_section2 {

      &_first {
        margin-bottom: -110px;
        &_img {
          margin-left: -565px;
        }
        &_text {
          margin-left: -890px;
          &_description {
            width: 215px;
            height: 95px;
            font-size: 20px;
            line-height: 30px;
            display: block;
          }
        }
      }

      &_second{
        &_btn {
          margin-left: -65px;
          margin-top: 50px;
          width: 182px;
          height: 32px;
        }
        &_icon {
          width: 30px;
          height: 30px;
          &_share {
            padding-top: 4px;
            margin-left: -1px;
          }
          &_img {
            width: 30px;
            height: 30px;
            padding-top: 0px;
          }
        }
      }
    }
  }
}

.to-start{
  // justify-content: startto-start;
}