.nft {
  //TO DELETE
  //margin-left: 300px;
  &_global {
    width: 730px;
    height: 315px;
    background: #ffffff;
    box-shadow: 0px 32.5631px 33.42px -24.8508px rgba(0, 0, 0, 0.12);
    border-radius: 17.1385px;

    &_name {
      color: rgba(113, 101, 190, 1);
      width: 82.26px;
      height: 19.71px;
      //font-family: DM Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 15.4246px;
      line-height: 20px;
      margin-left: 20px;
      padding-top: 20px;
    }

    &_description {
      margin-left: 37px;
      padding-top: 30px;
      padding-bottom: 20px;

      &_contentimg {
        margin-left: 20px;
        width: 22px;
        height: 22px;
        background: rgba(229, 228, 228, 0.3);
        border-radius: 50px;
        &_img {
          margin-left: 5px;
        }
      }

      &_contenticons {
        margin-left: 400px;

        &_fisrt {
          width: 24px;
          height: 24px;
          background: #ffffff;
          border: 0.412893px solid #ffffff;
          box-sizing: border-box;
          box-shadow: 4.12893px 4.12893px 16.5157px rgba(7, 17, 53, 0.1);
          border-radius: 8.25786px;
          margin-left: 9px;

          &_img {
            margin-left: 5px;
          }
        }
      }

      &_text {
        color: #65666a;
      }

      &_total {
        width: 74px;
        height: 18px;
        font-family: "Poppins-Regular";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        padding-top: 4px;
      }

      &_price {
        width: 41px;
        height: 23px;
        //font-family: DM Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.04em;
        margin-left: 10px;
      }

      &_unity {
        width: 8px;
        height: 16px;
        //font-family: DM Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin-left: 8px;
        padding-top: 4px;
      }
    }

    &_all {
      //margin-left: 37px;
      margin-right: 21px;
      &_item {
        flex-direction: column;
        margin-left: 30px;
        //margin-right: 15px;
        &_img {
          //width: 139.6px;
          height: 117.56px;
          //box-shadow: 0px 12.858px 22.9607px -3.67372px rgba(0, 0, 0, 0.15);
          //border-radius: 13.2954px;
        }

        &_text {
          width: 120.31px;
          height: 18.37px;
          font-style: normal;
          font-weight: 600;
          font-size: 11.9396px;
          line-height: 18px;
          font-family: "Poppins-Regular";
          color: #65666a;
          margin-left: 25px;
        }
      }

      &_more {
        margin-left: 600px;
        padding-top: 20px;
        &_text {
          width: 59px;
          height: 13px;
          //font-family: DM Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 13px;
          /* identical to box height */

          color: #a69ed6;
        }
      }
    }
  }
}
