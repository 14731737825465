.details {
  #status_0 {
    border: 2px solid rgba(0, 0, 0, 0);
  }
  #status_1 {
    border: 2px solid rgba(59, 167, 245, 1);
  }
  #status_2 {
    border: 2px solid rgba(56, 233, 52, 1);
  }
  #status_all {
    border: 2px solid $background-grey-opaque;
  }
  #active {
    border: 2px solid #3aebcb;
  }

  &_header {
    @include font_montserrat_regular;
    margin-top: 110px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $color-light-pink;
    margin-bottom: -100px;

    &_userpercent {
      width: 67px;
      height: 25px;
      background: linear-gradient(99.96deg, #3efcf0 12.13%, #23acbe 101.73%);
      border-radius: 19px;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: rgba(45, 43, 65, 1);
      margin-left: 2%;
    }
  }

  &_path {
    @include font_poppins_regular;
    font-size: $font-body-light;
    line-height: 18px;
    width: 300px;
    height: 20px;
    display: block;
    margin: 20px;
    margin-top: 110px;

    span {
      font-weight: 700;
    }
  }

  &_container {
    &_puzzle {
      display: inline-flex;
      width: 100%;
      height: 330px;
    }
    &_image {
      margin: 10px;
      &_meta {
        width: 360px;
        height: 311px;
        border-radius: 20px;
        box-shadow: 0px 39px 17px -31px rgba(27, 24, 24, 0.25);
      }

      &_carousel {
        display: inline-flex;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(12px);
        border-radius: 13px;
        padding: 5px;
        margin-left: -350px;
        margin-top: 235px;

        &_meta {
          cursor: pointer;
          width: 50px;
          height: 50px;
          margin: 5px;
          border-radius: 12px;
          //object-fit: cover;
        }
      }
    }
    &_stats {
      margin: 10px;
      //background-color: $color-grey-purple;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
      border-radius: 20px;

      &_content {
        width: 420px;
        max-height: 150px;
        margin: 30px;

        &_category {
          @include font_poppins_regular;
          font-size: $font-body-2;
          line-height: 18px;
          //color: $color-red-light;
          color: rgb(255, 119, 119);
          opacity: 0.6;
        }
        &_title {
          @include font_poppins_regular;
          font-size: $font-h3;
          line-height: 38px;
          font-weight: 700;
          //color: $color-text-header;
        }
        &_description {
          margin-top: 10px;
          @include font_poppins_regular;
          font-size: $font-body-2;
          line-height: 20px;
          //color: $color-text-header;
        }
      }

      &_bottom {
        margin: 20px;
        width: 450px;
        padding-top: 30px;
      }

      &_author {
        display: inline-flex;

        &_avatar {
          margin: 5px;
          &_meta {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }

        &_info {
          width: 200px;
          height: 40px;
          margin: 5px;
        }
        &_title {
          @include font_poppins_regular;
          font-size: $font-body-light;
          line-height: 12px;
          letter-spacing: 0.5px;
          color: $color-text-header;
        }
        &_name {
          @include font_poppins_regular;
          font-weight: 700;
          font-size: $font-stats;
          line-height: 15px;
          letter-spacing: 0.5px;
          color: $color-text-header;
          margin-top: 3px;
        }
      }

      &_social {
        display: inline-flex;
        width: 130px;
        height: 25px;
        margin-left: 65px;

        &_like {
          width: 64px;
          height: 23px;
          //background: rgba(19, 15, 64, 0.05);
          background: $background-color-light-dark;
          border-radius: 16px;
          margin: 5px;
          cursor: pointer;

          span {
            margin: 3px;
            @include font_poppins_regular;
            color: $color-text-header;
            font-size: $font-body-light;
          }

          &_meta {
            margin-top: 3px;
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }
        }
        &_comment {
          width: 64px;
          height: 23px;
          background: $background-color-light-dark;
          border-radius: 16px;
          margin: 5px;
          cursor: pointer;

          span {
            margin: 3px;
            @include font_poppins_regular;
            color: $color-text-header;
            font-size: $font-body-light;
          }

          &_meta {
            margin-top: 3px;
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }
        }
      }

      &_price {
        width: 225px;
        height: 318px;
        margin: 30px;

        &_all-button {
          margin-left: 10%;
          align-items: center;

          &_text {
            width: 38px;
            height: 27px;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
            color: #f9edff;
            @include font_poppins_regular;
          }
          &_sell {
            width: 70px;
            height: 47px;
            background: #212332;
            /* btn */

            box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            margin-right: 25px;
            align-items: center;
          }
          &_buy {
            width: 70px;
            height: 47px;
            background: linear-gradient(
              292.53deg,
              #9381ff 31.56%,
              rgba(147, 129, 255, 0.75) 97.92%
            );
            /* btn */

            box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
            border-radius: 10px;

            align-items: center;
          }
        }

        &_owned {
          text-align: center;
          margin-left: 15px;
          margin-bottom: 16px;
        }

        &_amount {
          margin: 15px;
          height: 60px;
          width: 100%;
          text-align: center;

          &_title {
            @include font_poppins_regular;
            font-size: $font-body-light;
            line-height: 16px;
            font-weight: 700;
          }
          &_piece {
            //color: $color-pink;
            @include font_poppins_regular;
            font-weight: 900;
            font-size: $font-h2;
            height: 45px;
            color: $color-white-grey;

            span,
            sup {
              font-weight: 100;
              font-size: $font-body-light;
              line-height: 16px;
            }
          }
        }

        &_infos {
          //height: 130px;
          height: 90px;
          width: 165px;
          margin-left: 40px;

          hr {
            width: 100%;
            //border: 1px solid #F3F3F3;
          }
        }

        &_stock {
          width: 100%;
          height: 15px;
          display: inline-flex;
          @include font_poppins_regular;
          &_title {
            width: 50%;
            text-align: left;
            font-size: $font-legend-info;
            line-height: 13px;
            color: rgba(119, 120, 119, 1);
          }
          &_amount {
            width: 50%;
            text-align: right;
            font-size: $font-body-3;
            line-height: 17px;
            font-weight: 700;
            color: rgba(133, 133, 133, 1);
          }
        }
        &_available {
          width: 100%;
          height: 15px;
          display: inline-flex;
          @include font_poppins_regular;
          &_title {
            width: 50%;
            text-align: left;
            font-size: $font-legend-info;
            line-height: 13px;
            color: rgba(119, 120, 119, 1);
          }
          &_amount {
            width: 50%;
            text-align: right;
            font-size: $font-body-3;
            line-height: 17px;
            font-weight: 700;
            color: rgba(133, 133, 133, 1);
          }
        }
        &_value {
          width: 100%;
          height: 15px;
          display: inline-flex;
          @include font_poppins_regular;
          &_title {
            width: 50%;
            text-align: left;
            font-size: $font-legend-info;
            line-height: 13px;
            color: rgba(119, 120, 119, 1);
          }
          &_amount {
            width: 50%;
            text-align: right;
            font-size: $font-body-3;
            line-height: 17px;
            font-weight: 700;
            color: rgba(133, 133, 133, 1);
          }
        }

        &_button {
          width: 162px;
          height: 45px;
          margin-left: 45px;
          cursor: pointer;

          background: $button_details;
          box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          align-items: center;
          span {
            margin: 10px;
            @include font_poppins_regular;
            font-size: $font-subtitle;
            line-height: 24px;
            color: white;
          }

          &_buy {
            margin-left: -5px;
          }
        }
      }
    }

    &_point {
      &_carousel {
        display: inline-flex;
        font-size: $font-h1-bigger;
        line-height: 2px;
        height: 30px;
        margin-left: 165px;
        &_1 {
          cursor: pointer;
          color: #a0a0a0;
          margin-left: 2px;
          margin-right: 2px;
        }
        &_2 {
          cursor: pointer;
          color: #d4dff0;
          margin-left: 2px;
          margin-right: 2px;
        }
        &_3 {
          cursor: pointer;
          color: #d4dff0;
          margin-left: 2px;
          margin-right: 2px;
        }
      }
    }
  }

  &_title {
    @include font_poppins_regular;
    font-size: $font-h2;
    line-height: 43px;
    margin: 25px;
    //color: $color-white;
  }

  &_section {
    &_description,
    &_activity,
    &_comments {
      @include font_poppins_regular;
      width: 360px;
      height: 614px;
      //background: $color-grey-purple;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      margin: 10px;

      &_title {
        color: $color-light-pink;
        text-align: left;
        margin: 30px;
        display: flex;
        align-items: center;
        width: 88%;
        justify-content: space-between;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        height: 16px;
        @include font_poppins_regular;

        &_length {
          width: 16px;
          height: 18px;
          font-weight: 600;
          font-size: 12px;
          line-height: 17px;
          color: rgba(76, 82, 112, 1);
          margin-right: 150px;
        }
      }
      &_content {
        margin-top: 15%;

        &_separation {
          margin-left: 15%;
        }
      }

      &_percent {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        color: $color-white-grey;
        img + div {
          margin-left: 3%;
        }

        &_sold {
          margin-right: 10%;
        }
      }

      &_info {
        width: auto;
        margin-left: 15%;
        &_text {
          color: $color-white-grey;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          margin-top: 5%;

          span {
            color: rgba(62, 252, 240, 1);
          }
        }

        &_number {
          color: $color-white-grey;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          margin-right: 15%;
          margin-top: 5%;

          span {
            color: rgba(62, 252, 240, 1);
          }
        }
      }
    }

    &_description {
      &_donut {
        //margin-left: 100px;
        //fill: white;
        &_content {
          &-legend {
            margin-top: 0px;
            display: flex;
          }
        }
      }
      &_secondary {
        padding-top: 52px;
        &_container {
          width: 250px;
          height: 22px;
          align-items: center;
          padding-bottom: 30px;
          @include font_poppins_regular;
          font-style: normal;
          &_percent {
            height: 12px;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            color: $color-white-grey;
          }
          &_username {
            width: 135px;
            height: 12px;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: $color-white-grey;
            margin-left: 5px;
          }
        }
      }

      &_content {
        &_row {
          @include font_poppins_regular;

          &_input {
            width: 250px;
            height: 40px;
            background: #212332;
            border-radius: 10px;
          }

          &_brand,
          &_model,
          &_glass {
            width: 100%;
            display: inline-flex;
            margin-top: 10px;
            margin-bottom: 10px;

            &_title {
              width: 50%;
              font-size: $font-legend-info;
              line-height: 13px;
              color: $color-text-header;
              text-align: left;
            }
            &_name {
              width: 50%;
              font-size: $font-body-3;
              font-weight: 700;
              line-height: 17px;
              color: $color-text-header;
              text-align: right;
            }
          }
        }

        &_title {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: rgba(76, 82, 112, 1);
          margin-left: 15%;
          margin-top: 10%;
        }
      }
      &_button {
        width: 173px;
        height: 53px;
        background: #fae0e0;
        border-radius: 10px;
        margin-top: 30px;
        margin-left: 25%;
        display: inline-flex;
        cursor: pointer;

        @include font_poppins_regular;
        font-size: $font-body-2;
        line-height: 21px;

        span {
          margin-top: 15px;
          color: rgba(14, 20, 70, 1);
        }

        img {
          margin: 10px;
        }
      }
    }

    &_activity {
      &_content {
        height: 40px;
        margin-bottom: 55px;
        &_row {
          display: inline-flex;
          margin-left: 30px;
          &_avatar {
            margin: 5px;
            &_meta {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }
          &_author {
            @include font_poppins_regular;
            margin: 5px;
          }

          &_name {
            font-size: $font-stats;
            line-height: 15px;
            letter-spacing: 0.5px;
            //color: rgb(36, 108, 209);
            color: $color-white-grey;

            &_text {
              font-family: Poppins-Regular;
              font-size: 0.688rem;
              line-height: 13px;
              color: #788292;
              margin: 8px;
            }
          }

          &_info {
            margin-top: 5px;
            letter-spacing: 0.5px;
            color: $color-text-header;
            font-size: $font-body-light;
            line-height: 12px;

            span {
              font-weight: 700;
            }
          }
        }
      }

      &_link {
        @include font_poppins_regular;
        font-size: $font-body-light;
        line-height: 12px;
        margin: 30px;
        text-decoration: underline;
        color: rgba(33, 149, 234, 1);

        a:visited,
        a:active,
        a:hover {
          color: rgba(33, 149, 234, 1);
        }
      }
    }

    &_comments {
      &_content {
        height: 40px;
        margin-bottom: 55px;
        &_row {
          display: inline-flex;
          margin-left: 30px;
          &_right {
            width: 260px;
            height: auto;
            background: #212332;
            border-radius: 10px;
            border: none;
            z-index: 10;
            &_enter {
              position: relative;
              width: 34px;
              height: 34px;
              align-self: end;
              margin-bottom: 2%;
              margin-left: -35px;
            }
            &_input {
              width: 250px;
              height: 40px;
              background: #212332;
              border-radius: 10px;
              border: none;
              @include font_poppins_regular;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: $color-white-grey;

              /*color: $color-white-grey;
              padding-top: 5%;
              width: 215px;
              height: auto;
              max-height: 60px;
              overflow: hidden;
              outline: transparent;
              padding-left: 2%;
              padding-bottom: 2%;
              scroll-behavior: auto;*/

              &::placeholder {
                width: 202px;
                height: 20px;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: #626167;
              }
            }
            /*[contentEditable=true]:empty:not(:focus):before{
              content:attr(data-text);
              color:rgba(98, 97, 103, 1);
            }*/
          }
          &_avatar {
            margin: 5px;
            &_meta {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }
          &_author {
            @include font_poppins_regular;
            margin: 8px;
          }

          &_name {
            font-size: $font-stats;
            line-height: 15px;
            letter-spacing: 0.5px;
            //color: rgb(36, 108, 209);
            //font-weight: 700;
            color: $color-white-grey;
          }

          &_info {
            margin-top: 5px;
            letter-spacing: 0.5px;
            color: $color-text-header;
            font-size: $font-body-light;
            line-height: 12px;

            span {
              font-weight: 700;
            }
          }

          &_date {
            @include font_poppins_regular;
            font-size: $font-date;
            line-height: 13px;
            color: rgba(120, 130, 146, 1);
            margin: 8px;
          }
        }
      }

      &_link {
        @include font_poppins_regular;
        font-size: $font-body-light;
        line-height: 12px;
        margin: 30px;
        text-decoration: underline;
        color: rgba(33, 149, 234, 1);

        a:visited,
        a:active,
        a:hover {
          color: rgba(33, 149, 234, 1);
        }
      }
    }
  }
}

.path-margin {
  margin-top: 110px !important;
}

.circle_margin {
  margin-left: 32%;
}

.details_container_stats_line {
  border-left: 1px solid $background-color-light-dark;
  margin-top: 30px;
  margin-bottom: 30px;
}
.circle_user {
  //height: 20px;
  //position: relative;
  height: 20px;
  margin-bottom: -48px;
  width: 230px;
  margin-left: 65px;
}
.circle-percentage-user {
  @include font_poppins_regular;
  text-anchor: middle;
  transform: translateY(-1.1em);

  font-style: normal;
  font-weight: normal;
  font-size: 4px;
  line-height: 8px;
  fill: $color-light-green;
}

.user_color {
  color: rgba(62, 252, 240, 1);
}

.no_margin_right {
  margin-right: 0;
}

.no_margin_left {
  margin-left: 0;
}

.date_text {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

/*.donutchart-innertext {
  fill: white;
}*/
.donuts {
  display: flex;
  margin-left: 106px;
  @include font_poppins_regular;
  //flex-direction: column;
  &-innertext {
    fill: $color-white-grey;
    transform: translate(2px, -50px);
  }
  &-legend {
    transform: translate(-169px, 231px);

    &-item {
      padding-bottom: 10px;
      transform: translate(200, 30);
      &-label {
        fill: $color-white-grey;
        height: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}
