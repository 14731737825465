@import "../../../../Style/_mixins";
@import "../../../../Style/_variables.scss";


.AnswerText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  gap: 0.8em;

  .answer-area {
    @include font_poppins_regular;

    resize: none;
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    background-color: $background-darker;
    outline: none;
    border: none;
    color: $color-white-grey;
    border-radius: 0.5em;
  }

  div {
    display: flex;
    justify-content: end;
    gap: 0.5em;

    button {
      @include font_poppins_regular;
      color: $color-white-grey;
      align-self: center;
      justify-self: flex-end;
      padding: 0.8em;
      border-radius: 0.4em;
      border: none;
      background: linear-gradient(292.53deg, #9381ff 31.56%, rgba(147, 129, 255, 0.75) 97.92%);
      width: max-content;
      cursor: pointer;
    }
  }
}
