@import "../../../../Style/_mixins";
@import "../../../../Style/_variables.scss";

.FAQAnswer {
  @include font_poppins_regular;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-white-grey;
  margin: 1em 0 1em 5%;
  background-color: $background-color-dark;
  padding: 1em 1.5em;
  border-radius: .3em;

  .answers-content {
    display: flex;
    flex-direction: column;
    gap: 2em;

    .username {
      margin-left: 20px;
      font-weight: bold;

    }

    .answer {
      font-size: .9em;
    }
  }

  .answers-like {
    display: flex;
    gap: .5em;
    cursor: pointer;
    margin-right: 20px;
    .unlike {
      width: 30px;
      height: 30px;
      background-image: url(../../../../Assets/vectors/faqs/love-outlined.svg);
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .like {
      width: 30px;
      height: 30px;

      background-image: url(../../../../Assets/vectors/faqs/love-filled.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      
      
    }
    .like-label{
      align-self: center;
      width: 60px;
      padding-right: 10px;
    }
  }
}