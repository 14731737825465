.transferout {
  width: 65%;
  &_transfer {
    margin-top: 5%;
    &_arrow {
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 5%;
    }
  }

  &_from {
    @include font_poppins_regular;
    color: $color-light-pink;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    &_choice {
      &_select {
        @include font_poppins_regular;
        color: $color-light-pink;

        width: 350px;
        height: auto;
        background: $color-borders;
        box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        border: none;
        appearance: none;
        margin-top: 1%;
        padding: 2%;

        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: $color-white-grey;

        &_dollar {
          width: 350px;
          height: 76px;
          background: $color-borders;
          box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
          border-radius: 20px;
        }

        &_account {
          @include font_poppins_regular;
          color: $color-light-pink;

          width: 165px;
          height: 76px;
          background: $color-borders;
          box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
          border-radius: 20px;
          border: none;
          padding: 15px;
          background: url(../../../../Assets/img/Account/select_button.svg)
            no-repeat 97%;
          padding-left: 20%;
          margin-top: 1%;
          padding-top: 8%;

          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 16px;
          color: $color-white-grey;
        }

        &_default {
          width: 350px;
          height: 76px;
          border-radius: 20px;
          cursor: pointer;
        }

        &_option {
          cursor: pointer;
          height: 60px;
          &_img {
            margin-right: 3%;
            margin-left: 10%;
            width: 30px;
            height: 30px;
          }

          &_checked {
            margin-left: 70%;
          }
        }

        &_text {
          &_currency {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $color-light-pink;
            margin-right: 5%;
          }

          &_amount {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: $color-white-grey;

            span {
              font-style: normal;
              font-weight: normal;
              font-size: 8.5px;
              line-height: 13px;
            }
          }

          &_iban {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: $color-white-grey;
            width: 170px;
          }
        }

        &_expand {
          &_currency {
            margin-left: 55%;
            width: 24px;
          }

          &_account {
            margin-left: 27%;
            width: 24px;
          }
        }
      }

      &_select:hover {
        background: $background-dark;
      }

      &_img {
        margin-right: 3%;
        width: 45px;
      }

      &_account {
        position: relative;
        left: 19%;
        top: 43px;
      }

      &_currency {
        position: relative;
        left: 20%;
        top: 43px;
      }
    }

    &_line {
      margin-bottom: 3%;
    }
  }

  &_details {
    @include font_poppins_regular;
    color: $color-light-pink;
    margin-top: 10%;
    &_amount {
      @include detail;
      margin-left: 7%;
      &_input {
        @include font_poppins_regular;
        width: 70px;
        appearance: none;
        background: transparent;
        border: none;
        color: $color-white-grey;

        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: $color-white-grey;
      }
      &_input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
      

      &_img {
        margin-left: 30%;
      }

      &_currency {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;

        color: $color-white-grey;
      }
    }

    &_date {
      @include detail;

      &_input {
        @include font_poppins_regular;
        width: 200px;
        appearance: none;
        background: transparent;
        border: none;
        color: $color-white-grey;

        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: $color-white-grey;
      }

      input[type="date"]::-webkit-calendar-picker-indicator {
        background: url(../../../../Assets/img/Account/transfer_date.svg)
          no-repeat;
        height: 30px;
        width: 30px;
        cursor: pointer;
      }

      &_img {
        margin-left: 30%;
      }
    }

    &_label {
      @include detail;

      &_img {
        margin-left: 3%;
      }

      &_input {
        @include font_poppins_regular;
        width: 170px;
        appearance: none;
        background: transparent;
        border: none;
        color: $color-white-grey;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $color-white-grey;
        margin-top: 5%;
      }
    }
  }

  &_footer {
    @include font_poppins_regular;
    align-items: flex-end;
    margin-top: 5%;
    margin-right: 16%;
    &_clause {
      width: 160px;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 13px;
      color: rgba(171, 163, 171, 1);

      margin-bottom: 2%;

      &_img {
        margin-right: 4%;
      }
    }

    &_button {
      width: 180px;
      height: 48px;
      background: linear-gradient(292.53deg, #9381ff 31.56%, #9381ff 97.92%);
      border-radius: 10px;
      cursor: pointer;

      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      color: rgba(249, 237, 255, 1);
    }
  }

  &_popup {
    @include font_poppins_regular;
    width: 320px;
    height: 328px;
    box-shadow: 0px 19px 72px #000000;
    border-radius: 20px;
    background: $background-dark;
    position: absolute;
    z-index: 10;
    align-self: center;
    top: 30%;

    &_header {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      color: $color-light-pink;
      margin-top: 10%;
      margin-left: 10%;
    }

    &_total {
      align-self: center;
      margin-top: 10%;
      img {
        width: 30px;
        height: 30px;
      }
      &_img {
        margin-right: -5%;
      }
      &_amount {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: $color-white-grey;
      }
    }

    &_buttons {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: $color-white-grey;
      margin-top: 15%;

      &_cancel {
        width: 120px;
        height: 38px;
        border-radius: 20px;
        background: $color-borders;
        font-size: 16px;
        line-height: 17px;
        margin-right: 5%;
        margin-top: 7%;
        cursor: pointer;
      }

      &_cancel:hover {
        background: rgba(52, 55, 77, 1);
      }

      &_confirm {
        width: 120px;
        height: 38px;
        border-radius: 20px;
        background: $color-pure-purple;
        font-size: 16px;
        line-height: 17px;
        margin-top: 7%;
        cursor: pointer;
      }

      &_confirm:hover {
        background: rgba(96, 80, 222, 1);
      }
    }

    &_success {
      height: 90px;
      margin-top: -58%;
    }

    &_confirm_text {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      color: $color-white-grey;
      margin-top: 15%;
    }
  }
}

#overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.currency_code_margin {
  margin-left: 10%;
}

.currency_margin {
  margin-right: 29%;
  margin-left: 14%;
  width: 37px;
}

.selected_width {
  width: 70px;
}

.circle-label {
  transform: translateY(0.25em);
  color: $color-white-grey;
}

.circle-percentage {
  @include font_poppins_regular;
  text-anchor: middle;
  transform: translateY(-0.25em);

  font-style: normal;
  font-weight: normal;
  font-size: 6px;
  line-height: 12px;
  fill: $color-white-grey;
}

.circle-text {
  font-size: 0.2em;
  text-transform: uppercase;
  text-anchor: middle;
  transform: translateY(0.7em);
}

.circle_container {
  height: 130px;
}
