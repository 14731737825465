.video-container-default {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Assurez-vous que la vidéo couvre tout l'espace */
    }
  
    .content-default {
      position: absolute;
      top: 50%; /* Centrez le contenu verticalement */
      left: 50%; /* Centrez le contenu horizontalement */
      transform: translate(-50%, -50%); /* Centrez le contenu correctement */
      text-align: center; /* Centrez le texte horizontalement */
      z-index: 1;
    }
  
    .button-default {
      display: inline-block;
      padding: 10px 20px;
      background-color: #1f202d;
      color: white;
      text-decoration: none;
      margin: 10px;
      border-radius: 5px;
    }
  }
  