// font sizes
$font-info: 0.5rem; //8px
$font-legend-info: 0.625rem; //10px
$font-date: 0.688rem; //11px
$font-body-light: 0.75rem; //12px
$font-body-3: 0.813rem; //13px
$font-body-2: 0.875rem; //14px
$font-stats: 0.938rem; //15px
$font-menu: 1rem; //16px
$font-menu-2: 1.063rem; //17px
$font-subtitle: 1.125rem; //18px
$font-button: 1.25rem; //20px
$font-h2-h3: 1.5rem; //24px
$font-h3: 1.75rem; //28px
$font-h2: 2.25rem; //36px
$font-h1: 3rem; //48px
$font-h1-bigger: 3.125rem; //50px

// font family
@font-face {
  font-family: "InriaSerif-Regular";
  src: url("../Fonts/InriaSerif-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../Fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SFPro";
  src: url("../Fonts/SFPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Regular";
  src: url("../Fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../Fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Anton-Regular";
  src: url("../Fonts/Anton-Regular.ttf") format("truetype");
}
// shadows
$shadow_navbar: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
$shadow_searchbar: 0px 3px 7px rgba(0, 0, 0, 0.05);
$shadow_popup: 0px 37px 61px rgba(0, 0, 0, 0.14);
$shadow_currency: 0px 32px 33px -25px rgba(0, 0, 0, 0.12);
$shadow_actions: 7px 7px 30px rgba(7, 17, 53, 0.1);
$shadow_account: 0px 4px 30px rgba(0, 0, 0, 0.08);
$shadow_wallet_dollar: 0px 11px 11px rgba(0, 0, 0, 0.02);
$shadow_puzzle_count: 0px 4px 19px rgba(0, 0, 0, 0.12);
$shadow_confirm: 0px 9px 23px rgba(0, 0, 0, 0.15);
$light_shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
$shadow_market: 0px 4px 30px rgba(0, 0, 0, 0.3);

//border

// colors
$color-black: rgb(0, 0, 0);
$avatar_hover: rgba(0, 0, 0, 0.74);
$color-background: rgb(253, 248, 255);
$color-white_puzzle: rgb(216, 215, 225);
$color-border: rgb(93, 21, 0);
$color-text-header: rgb(154, 141, 168);
$color-text-purple: rgb(227, 222, 255);

$color-blue-dark-details: rgba(32, 55, 88, 1);

$color-button: rgb(178, 64, 93);
$color-red-light: rgba(199, 37, 53, 1);
$color-grey: rgba(86, 82, 82, 1);
$color-light-grey: rgba(101, 102, 106, 1);
$color-text-nft: rgb(101, 102, 106);
$color-path: rgba(122, 117, 117, 1);
$color-text-popup: rgb(176, 176, 176);
$color-text-wallets: rgb(205, 204, 205);
$color-text-category: rgb(151, 112, 101);
$color-background-img-home: rgb(23, 1, 10);
$color-cyan: rgb(103, 206, 244);
$color-purple: rgba(97, 118, 246, 1);
$color-light-green: rgb(62, 252, 240);
//##########################################
$color-blue: rgb(14, 20, 70);
$color-blue-ligther: rgb(36, 108, 209);
$color-white: rgb(255, 255, 255);
$color-purple: rgba(53, 46, 91, 1);
$color-grey-purple: rgba(42, 45, 62, 1);
$color-light-purple2: rgb(147, 129, 255);
$color-lighter-purple: rgba(113, 101, 190, 1);
$color-light-pink: rgb(249, 237, 255);
$color-pink: rgba(189, 2, 255, 1);
$color-dark-blue: rgb(17, 17, 41);
$color-light-purple: rgb(154, 141, 168);
$color-dark-white: rgb(160, 160, 160);
$color-blue-white: rgb(212, 223, 240);
$color-pure-white: rgb(252, 251, 251);
$color-light_black: rgba(196, 196, 196, 1);
$color-red-white: rgb(231, 230, 230);
$color-dark-red: rgb(57, 37, 41);
$color-dark-grey: rgb(103, 103, 129);
//$color-text-input : rgb(154,141,168)
$color-white: rgb(255, 255, 255);
$color-trasaction: rgb(235, 176, 182);
$color-text-puzzle: rgb(33, 33, 33);
$color-wallet: rgb(245, 245, 245);
$color-blue-dark: rgb(14, 20, 70);
$color-black-dark-text: rgb(33, 33, 33);
$color-ligther-count: rgb(16, 15, 17);
$color-gray-watch: rgb(104, 104, 104);
$color-white-opacite: rgba(249, 237, 255, 1);
$color-blue-dark-text: rgb(3, 13, 21);
$color-gray-total: rgb(133, 133, 133);
$color-lighter-confirm: rgb(249, 237, 255);
$color-stat-title: rgb(216, 204, 221);
$color-billing: rgb(86, 82, 82);
$color-gray-order-item: rgb(119, 120, 119);
$color-blue-light-btn: rgb(33, 150, 234);
$color-btn-add: rgb(109, 103, 103);
$color-violet-light: rgb(242, 159, 148);
$color-violet-dark-btn: rgb(237, 179, 180);
$color-gray-line: rgb(243, 243, 243);

$color-light-pink: rgba(227, 222, 255, 1);
$color-pure-grey: rgb(177, 177, 177);
$color-white-grey: rgba(216, 215, 225, 1);
$color-grey-placeholder: rgba(98, 97, 103, 1);
$color-pure-purple: rgb(155, 142, 255);
$color-faq-search:rgba(198, 201, 223, 1);
$color-faq-sort: rgba(134, 132, 157, 1);

$color-dark-nav: rgba(21, 23, 33, 0.9);
$color-red-error: rgba(255, 116, 146, 1);

/**** Dark ***/
$background-darker: rgb(23, 25, 35);
$background-dark: rgb(33, 35, 50);
$color-borders: rgb(42, 45, 62);
// background colors
$background-color-black: rgba(38, 62, 62, 0.4);
$background-color-white: rgb(255, 255, 255);
$backgroud-color-white-opac: rgba(255, 255, 255, 0.4);
$backgroud-color-white-opacity: rgba(255, 255, 255, 0.3);
$background-color-dark: rgba(42, 45, 62, 1);

$background-color-light-dark: rgba(33, 35, 50, 1);
$background-color-light-grey: rgba(229, 228, 228, 0.5);
$background-color-light-black: rgba(21, 23, 33, 1);
$background-color-navbar: rgba(21, 23, 33, 0.9);
$background-color-light-dark: rgba(33, 35, 50, 1);
$background_red: rgba(252, 86, 140, 1);
$background-grey-opaque: rgba(233, 236, 253, 0.5);
$background-black-line : rgba(38, 35, 35, 1);

// gradients
$gradient-btn: linear-gradient(
  160.36deg,
  #719ef6 1.15%,
  #242959 111.28%,
  #242959 111.28%
);

$button_details: linear-gradient(180deg, #6577ea 0%, #6577ea 100%);
$background_profile: linear-gradient(111.1deg, #2b273d 1.73%, #131213 100%);
$background-gradient-red: linear-gradient(180deg, #af405c 0%, #7c1d29 100%);
$gradient_btn_confirm: linear-gradient(
  292.53deg,
  #9381ff 31.56%,
  rgba(147, 129, 255, 0.75) 97.92%
);

$button_details: linear-gradient(180deg, #6577ea 0%, #6577ea 100%);
// shadows
$div_shadow: 0px 0px 40px rgba(0, 23, 48, 0.0784314);
$input_shadow: 0px 3px 14px rgba(0, 0, 0, 0.7);

$button_details: linear-gradient(180deg, #6577ea 0%, #6577ea 100%);
// shadows
$div_shadow: 0px 0px 40px rgba(0, 23, 48, 0.0784314);
$input_shadow: 0px 3px 14px rgba(0, 0, 0, 0.7);
