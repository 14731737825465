.my_wallet {

    &_container {
        margin-top: -65px;
        background: #2a2d3e;
        border-radius: 20px;
        width: 1120px;
        height: auto;
        padding-top: 9px;
    padding-bottom: 26px;
        
        &_content {



            &_tab {
                background: #212432;
                border-radius: 10px;
                height: 52px;
                margin-top: 23px;
                margin-left: 2%;
                margin-right: 2%;
                width : 96%;
                margin-bottom: 33px;

                &_row {
                    color: #d8d7e1;
                    @include font_poppins_regular;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    margin-left: 1%;
                    margin-right: 1%;
                    padding-top: 17px;

                    &_currency {
                        width : 22%;
                    }
                    &_amount {
                        width : 25%;
                    }
                }
            }

            &_elems {
                width : 96%;
                &_row {
                    color: #d8d7e1;
                    @include font_poppins_regular;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                    &_currency {
                        width: 21.5%;
                        //border: 1px solid red;
                        margin-left: 3.1%;
                    }
                    &_amount {
                        width : 24.5%;
                    }
                }
            }

            &_line {
                background: #212432;
                border-radius: 10px;
                height: 0.1px;
                margin: 33px;
                width: 94%;
            }
        }
    }
}