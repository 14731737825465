.app {
  &_content {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 135px);

  }

  &_light {
    background: $background-color-light-dark;
  }
  &_dark {
    background: $color-background;
  }
}