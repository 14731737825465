.market {
  &_header {
    &_title {
      @include font_poppins_regular;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 40px;
      color: $color-light-pink;
      margin-bottom: 2%;
    }

    &_content {
      @include font_poppins_regular;
      height: 301px;
      //border: 5px solid red;
      width: 1240px;
      // background: url(../../../../Assets/img/Market/header_bg.svg) no-repeat;
      border-radius: 30px;
      box-shadow: 0px 35.5279px 27.9148px -25.377px rgba(0, 0, 0, 0.05);
      overflow-wrap: hidden;

      &_left {
        margin-left: 75px;

        &_class {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
        }

        &_text {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          margin-top: 1%;
        }

        &_button {
          margin-top: 3%;
          color: $color-white;
          width: 212px;
          height: 40px;
          background: linear-gradient(
            160.36deg,
            #719ef6 1.15%,
            #242959 111.28%,
            #242959 111.28%
          );
          border-radius: 6px;
          cursor: pointer;
        }
      }

      &_right {
        background: url(../../../../Assets/img/Market/half_circles.svg)
          no-repeat;
        margin-left: 17%;
        margin-top: 2.25%;
        width: 406px;
        height: 273px;

        &_img {
          position: relative;
          bottom: 28%;
          width: 292px;
          height: 412px;
        }
      }
    }
  }



  &_body {
    width: 1240px;
    //height: 1065px;
    border-radius: 20px;
    background: $background-color-light-dark;
    box-shadow: $shadow_market;

    &_right {
      @include font_poppins_regular;
      width: 79.5%;
      padding: 2%;
      &_title {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        color: $color-pure-purple;
      }

      &_header {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: $color-light-pink;
      }

      &_filters {
        margin-top: 2%;
        margin-bottom: 3%;
        &_select {
          @include font_poppins_regular;
          width: 150px;
          height: 26px;
          border-radius: 23px;
          background: rgb(38, 41, 56);
          border: 1px solid rgb(27, 29, 39);

          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 18px;
          color: $color-white-grey;

          &_popup {
            z-index: 30;
            position: absolute;
            background-color: rgba(27, 29, 39, 1);
            box-shadow: $shadow_popup;
            border-radius: 10px;
            width: 167px;
            // TODO : COMMENT TO REMOVE
            //height: 188px;
            // TODO : REMOVE THE LINE
            height: 220px;
            transform: translate(-4%, 4%);
          }
        }

        &_search {
          &_input {
            width: 150px;
            height: 26px;

            border: 1px solid #1b1d27;
            box-sizing: border-box;
            border-radius: 23px;
            padding-left: 26px;

            &_searching {
              color: rgba(155, 142, 255, 1);
              background: rgba(23, 25, 35, 1);
            }
            &_default {
              background: #262938;
            }
          }

          &_img {
            position: relative;
            margin-right: -20px;
            margin-bottom: -2px;
          }

          &_delete {
            position: relative;
            margin-left: -16px;
            margin-bottom: -2px;
          }
        }
      }

      &_puzzles {
        justify-content: unset;
        height: auto;
        //max-height: 100%;
        //margin-left: -64px;
      }

      &_footer {
        align-self: center;
        width: 150px;
        position: absolute;
        top: 200%;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        &_disabledarrow {
          color: rgba(198, 201, 223, 0.2);
          font-size: 23px;
        }

        &_enabledarrow {
          color: rgba(198, 201, 223, 1);
          font-size: 23px;
          cursor: pointer;
        }

        &_selectedpage {
          width: 22px;
          height: 22px;
          background: rgba(25, 27, 38, 0.5);
          border-radius: 27px;
          color: rgba(198, 201, 223, 1);
        }

        &_unselectedpage {
          color: rgba(198, 201, 223, 1);
          cursor: pointer;
        }
      }
    }

    &_left {
      width: 18.5%;
      height: 100%;
      background: rgba(25, 27, 38, 0.5);
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;

      &_img {
        margin-right: 10%;
      }

      &_market {
        width: auto;
        height: auto;
        background: $background-darker;
        border-radius: 10px;
        padding: 5px;

        &_content {
          &:hover {
            background: rgba(255, 255, 255, 0.2) !important;
            border-radius: 5px;
            //width: 150px;
            //height: 30px;
            padding-left: 8px;
            padding-right: 8px;
            background-image: url(../../../../Assets/vectors/Market/to_valid.svg)
              no-repeat;
            background-position: 92%;
          }
          display: flex;
          margin: 7px 10px 0 10px;
          padding-top: 9px;
          padding-bottom: 9px;
          cursor: pointer;
          //border: 1px solid red;

          width: 136px;
          padding-left: 7px;

          &_text {
            width: 88%;
            height: 13px;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 13px;
            color: $background-color-white;
            @include font_poppins_regular;
          }

          &_img-valid {
            //margin-left: 58px;
          }
        }
        &_first {
          margin: 10px;
          display: flex;
          &_text {
            width: auto;
            height: 27px;
            @include font_poppins_regular;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            color: $background-color-white;
          }

          &_img {
            margin-left: 28px;
            width: 24px;
            height: 24px;
          }
        }

        &_line-market {
          border: 1px solid #202230;
          //border: 0.5px solid $background-dark;
          margin-top: 10px;
          margin-bottom: 10px;
          margin: 0 10px 0 10px;
        }
      }

      &_expand {
        @include font_poppins_regular;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: $color-white;

        width: 170px;
        height: 47px;
        border-radius: 10px;
        background-color: $background-color-light-dark !important;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url(../../../../Assets/img/Market/expand_button.svg)
          no-repeat;
        background-position: 95%;
        padding: 2%;
      }

      &_expand-market {
        @include font_poppins_regular;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: $color-white;

        width: auto;
        height: 47px;
        border-radius: 10px;
        background-color: $background-color-light-dark !important;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        /*background: url(../../../../Assets/img/Market/expand_button.svg)
          no-repeat;
        background-position: 95%;*/
        padding: 3%;
        display: flex;

        &_text {
          width: auto;
          height: 27px;
        }
      }

      &_expand:focus {
        background: url(../../../../Assets/img/Market/expand_button_active.svg)
          no-repeat;
        background-position: 95%;
      }

      &_classes {
        margin-top: 25%;
        &_title {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: rgba(65, 70, 96, 1);
          margin-bottom: 10%;
        }

        &_content {
          @include font_poppins_regular;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: $color-white;
          &_all {
            cursor: pointer;
            width: 170px;
            height: 44px;
            border-radius: 10px;
            background: $color-pure-purple;
            padding-left: 5%;
          }

          &_class {
            cursor: pointer;
            width: 170px;
            height: 44px;
            border-radius: 10px;
            background: transparent;
            padding-left: 5%;
          }
        }
      }
    }
  }
}

/***** pagination *******************/
.container_pagination {
  padding-top: 80px;
}
.user {
  //border: 2px solid black;
  //border-radius: 10px;
  //background-color: #b3e3ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
  height: 400px;
  margin: 20px;
}

.paginationBttns {
  //width: 80%;
  //height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding-left: 6px;
  padding-right: 6px;
  margin: 8px;
  border-radius: 50px;
  //border: 1px solid #2b2eff;
  color: #c6c9df;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: #c6c9df;
  //background-color: #2b2eff;
  background: rgba(25, 27, 38, 0.5);
}

.paginationActive a {
  color: #c6c9df;
  background: rgba(25, 27, 38, 0.5);
  border-radius: 27px;
  width: 22px;
  height: 22px;
}

.paginationDisabled a {
  color: grey;
  //background-color: grey;
}

.market-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // border-radius: 30px;
  margin-bottom: 30px;

  &:before {
      content: '';
      padding-bottom: 33.713784%;
      display: block;
  }
}
