.exchange {
  &_img {
    width: 131px;
    height: 109px;
    background: url(../../../../Assets/img/Account/exchange_img.svg);
    cursor: pointer;
    margin-bottom: 1%;
  }

  &_img:hover {
    background: url(../../../../Assets/img/Account/exchange_active.svg)
      no-repeat;
  }

  &_titles {
    @include font_poppins_regular;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: $color-light-pink;

    margin-top: 20%;

    &_send {
      margin-bottom: 2%;
      margin-left: 2%;
    }

    &_get {
      margin-bottom: 2%;
      margin-right: 2%;
    }
  }

  &_container {
    @include font_poppins_regular;
    width: 1064px;
    height: 105px;
    background-color: $color-borders;
    box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
    border-radius: 20px;

    &_content {
      background: #212332;
      border-radius: 10px 10px 10px 10px;
      display: flex;
      width: 200px;
      margin-top: 193px;
      position: relative;
      &-select {
        display: flex;
        width: 20px;
        margin-top: 12px;
        margin-left: 10px;
        &_content {
          display: flex;
          padding-top: 10px;

          &_left {
            margin-left: 30px;
          }
          &_right {
            margin-right: 30px;
          }
          &_item {
            display: flex;
            align-items: center;
            padding-top: 20px;
            margin-left: -12%;

            &_img {
              width: 30px;
              height: 30px;
            }
            &_valid {
              margin-left: 46px;
            }
            & > span {
              margin-left: 17px;
              color: rgba(227, 222, 255, 1);
              width: 31px;
              height: 21px;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              @include font_poppins_regular;
              cursor: pointer;
            }
          }
        }
        &_text {
          margin-left: 15px;

          & > span {
            width: 47px;
            height: 24px;
            color: #687097;
            @include font_poppins_regular;
          }
        }
      }
    }

    /** **/
    &_contentRight {
      background: #212332;
      border-radius: 10px 10px 10px 10px;
      display: flex;
      width: 200px;
      margin-top: 203px;
      position: relative;
      margin-left: 17%;
      &-select {
        display: flex;
        width: 17%;
        margin-top: 12px;
        margin-left: 45px;
        &_content {
          display: flex;
          padding-top: 10px;

          &_icon {
            margin-right: 15%
          }
          &_left {
            margin-left: 30px;
          }
          &_right {
            margin-right: 30px;
          }

          &_itemRight {
            display: flex;
            align-items: center;
            padding-top: 20px;
            margin-right: -9%;

            &_valid {
              margin-left: 17px;
            }
            & > span {
              //margin-left: 30px;
              color: rgba(227, 222, 255, 1);
              width: 86px;
              height: 21px;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              @include font_poppins_regular;
              cursor: pointer;
            }
          }
        }
        &_text {
          margin-left: 15px;

          & > span {
            width: 47px;
            height: 24px;
            color: #687097;
            @include font_poppins_regular;
          }
        }
      }
    }
    /** **/
    &_left {
      display: flex;
      width: 200px;
      height: auto;
      border-radius: 10px;
      z-index: 5;
      padding: 10px;
      align-self: flex-start;
      margin-top: 1.5%;
      margin-left: 1%;
      &_text {
        margin-left: 15px;

        & > span {
          width: 47px;
          height: 24px;
          color: #687097;
          @include font_poppins_regular;
        }
      }
    }

    &_left:hover {
      background: $background-dark;
    }

    &_right {
      display: flex;
      width: 200px;
      margin-left: 10px;
      padding: 10px;
      align-self: flex-start;
      z-index: 5;
      margin-top: 1.5%;
      margin-right: 1%;
      border-radius: 10px;

      &_text {
        margin-right: 36px;

        & > span {
          width: 47px;
          height: 24px;
          color: #687097;
          @include font_poppins_regular;
        }
      }
    }

    &_right:hover {
      background: $background-dark;
    }
    &_img-down {
      position: relative;
      margin-top: 33px;
      margin-right: -14px;
      height: fit-content;
    }
    &_img-right {
      position: relative;
      margin-top: 33px;
      margin-left: -14px;
    }
    &_sendimg {
      //margin-right: -5%;
      width: 45px;
      height: 45px;
      margin-top: 4%;
      margin-left: 2%;

    }

    &_getimg {
      margin-left: -6%;
      width: 45px;
      height: 45px;
    }
    &_getimgRight {
      margin-left: 15px;
      width: 45px;
      height: 45px;
    }

    &_select {
      &_send {
        width: 200px;
        height: 65px;
        background: url(../../../../Assets/img/Account/expand_button.svg)
          no-repeat 3% 80%;
        border: none;
        border-radius: 10px;
        appearance: none;

        color: rgba(104, 112, 151, 1);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-right: 1%;

        text-align: center;
        z-index: 2;
      }

      &_get {
        width: 200px;
        height: 65px;
        background: url(../../../../Assets/img/Account/expand_button.svg)
          no-repeat 97% 80%;
        border: none;
        border-radius: 10px;
        appearance: none;
        color: rgba(104, 112, 151, 1);
        text-align: right;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-right: 1%;
        text-align: center;
        z-index: 2;
      }
    }

    &_input {
      @include font_poppins_regular;
      width: 250px;
      height: 65px;
      border-radius: 10px;
      border: none;
      background: $color-borders;

      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 42px;
      color: $color-white-grey;
      margin-left: 14px;
      //
      //margin-left: 15px;
      //
    }
    input:hover {
      background: $background-dark;
    }

    input[type="number"]::-webkit-inner-spin-button {
      appearance: none;
    }
  }

  &_footer {
    @include font_poppins_regular;
    align-items: flex-end;

    &_taux {
      align-self: flex-start;
      color: $color-white-grey;
      &_img {
        margin-top: 6%;
      }
    }
    &_clause {
      width: 160px;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 13px;
      color: rgba(171, 163, 171, 1);

      margin-bottom: 2%;

      &_img {
        margin-right: 4%;
      }
    }

    &_button {
      width: 180px;
      height: 48px;
      background: linear-gradient(292.53deg, #9381ff 31.56%, #9381ff 97.92%);
      border-radius: 10px;
      cursor: pointer;

      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      color: rgba(249, 237, 255, 1);
    }
  }

  &_popup {
    @include font_poppins_regular;
    width: 320px;
    height: 288px;
    box-shadow: 0px 19px 72px #000000;
    border-radius: 20px;
    background: $background-dark;
    position: absolute;
    z-index: 10;
    align-self: center;
    top: 30%;

    &_header {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      color: $color-light-pink;
      margin-top: 10%;
      margin-left: 10%;
    }

    &_total {
      align-self: center;
      margin-top: 10%;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: $color-white-grey;
      img {
        width: 30px;
        height: 30px;
      }
      &_img {
        margin-right: -5%;
        margin-left: 10%;
      }
      &_send {
        margin-right: 15%;
        width: 60px;
      }

      &_get {
        margin-left: 15%;
        width: 60px;
      }
    }

    &_buttons {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: $color-white-grey;
      margin-top: 15%;

      &_cancel {
        width: 120px;
        height: 38px;
        border-radius: 20px;
        background: $color-borders;
        font-size: 16px;
        line-height: 17px;
        margin-right: 5%;
        margin-top: 7%;
        cursor: pointer;
      }

      &_confirm {
        width: 120px;
        height: 38px;
        border-radius: 20px;
        background: $color-pure-purple;
        font-size: 16px;
        line-height: 17px;
        margin-top: 7%;
        cursor: pointer;
      }
    }

    &_success {
      height: 250px;
    }
  }
}

#overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.background_dark {
  background: $background-dark !important;
}