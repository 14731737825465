@import "../../../../Style/_mixins.scss";
@import "../../../../Style/_variables.scss";

.Block {
  @include font_poppins_regular;
  color: $color-text-purple;
  margin-top: 20px;

  .block-title {
    margin-bottom: 30px;
  }
  .users-list{
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    gap: .5em;
    max-height: 50vh;
    overflow-y: auto;

  }
  .decision-buttons{
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }


}