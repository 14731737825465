@import "../../../../../Style/_mixins.scss";
@import "../../../../../Style/variables";


.GroupItem {
  @include font_poppins_regular;
  color: #D8D7E1;
  display: flex;
  align-items: center;
  gap: 1em;
  padding: .8em 1em;
  border-radius: 2em;
  background-color: #1C1E29;

  .details {
    display: flex;
    gap: .5em;


    .icon {
      background-image: url(../../../../../Assets/img/User/user-outllined.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 18px;
      height: 18px;



    }

    
  }
}
.edit-wrapper {
  padding: .5em;
  background-color: #212332;
  border-radius: 50%;
  .edit {
    background-image: url(../../../../../Assets/vectors/Profile/basic-edit-pencil.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
}
.group-next{
  border-radius: 10px;
  background-color: $background-color-light-black;
  cursor: pointer;
  font-size: small; 
  color: #D8D7E1;
  font-family: Arial, Helvetica, sans-serif;
}