/****************** Dark and light mode  **************/

.light_text_color_puzzle {
  color: $color-text-puzzle;
}
.light_text_item_order_first {
  color: $color-gray-order-item;
}
.light_text_item_order_second {
  color: $color-gray-total;
}
.light_total {
  color: $color-gray-total;
}
.dark-black-color {
  color: $color-black-dark-text;
}
.light_line {
  border: 0.5px solid $color-gray-line;
}
.dark_line {
  border: 0.5px solid $background-dark;
}
.light_text_name {
  color: $color-billing;
}
.light-details_path {
  color: $color-path;
}

/************************** end dark and light mode *******************/
.purchase {
  //padding-top: 100px;
}

.container_purchase {
  padding-top: 90px;
}

.first {
  flex-direction: column;
}

.transaction {
  width: 740px;
  height: 339px;

  //background: #ffffff;
  //background: $color-white;
  //background: $color-borders;
  box-shadow: $shadow_account;
  border-radius: 20px;
  padding-top: 17px;
}

.billing {
  width: 360px;
  height: 280px;
  //background: $color-white;
  //background: $color-borders;
  //box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  box-shadow: $shadow_account;
  border-radius: 20px;
}

.stats {
  width: 360px;
  height: 280px;
  //background: #ffffff;
  //background: $color-white;
  //background: $color-borders;
  //box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  box-shadow: $shadow_account;
  border-radius: 20px;
  margin-left: 20px;
}

.billing_bloc {
  padding-top: 20px;
}

.order_purchase {
  width: 360px;
  height: 444.5px;
  //background: $color-white;
  //background: $color-borders;
  box-shadow: $shadow_account;
  border-radius: 20px;
  margin-left: 20px;
}

.transaction_container_first {
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}

.transaction_text {
  width: 96px;
  height: 19px;
  //font-family: "Poppins-Regular";
  @include font_poppins_regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: $color-light-pink;
  //color: $color-trasaction;
}

.transaction_buy-sell {
  width: 105px;
  height: 24px;
  background: #212332;
  box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  &_content {
    background: linear-gradient(292.53deg, #9381ff 31.56%, #9381ff 97.92%);
    border-radius: 5px;
  }
  &_buy {
    width: 50%;
    //border: 1px solid red;
  }

  &_sell {
    width: 50%;

    & > span {
      width: 22px;
      height: 18px;
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      color: $color-white-grey;
      @include font_poppins_regular;
    }
  }
}

.puzzle_number {
  width: 173px;
  height: 21px;
  margin-right: 77px;
  //color: $color-black-dark-text;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  @include font_poppins_regular;
  color: $color-white-grey;
  //color: $color-text-puzzle;
  //color: $color-white;
  //justify-content: flex-start;
}

.to_all {
  margin-left: 20px;
}

.nb_puzzle_content {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;

  &_error {
    &_text {
      width: 203px;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: $background_red;
      @include font_poppins_regular;
    }
  }
}

.wallet_content_puzzle-currency {
  //padding-top: 32px;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  /* align-items: center; */
  /* position: relative; */
  height: 40px;
  
  &_select {
    background-color: $background-darker;
    width: 172px;
    
    height: auto;
    border-radius: 10px;
    padding: 2%;
    z-index: 5;

    &_all {
      height: fit-content;
    }
    &_second-content {
      margin-top: 13px;
      //margin-bottom: 13px;
    }
    &_first-item {
      margin-bottom: 4px;
      background-image: url(../../../Assets/vectors/purchase/normal_down.svg);
      background-repeat: no-repeat;
      background-position: 100%;
    }
    &_first-item:hover {
      background-image: url(../../../Assets/vectors/purchase/normal_down_hover.svg);
    }
    &_default {
      
      width: 172px;
      align-items: center;
      /* justify-content: center; */
      //margin-top: -5px;

      &_down {
        margin-left: 12px;
      }
      &_icon {
        width: 20px;
        height: 20px;
      }

      &_text {
        margin-left: 10px;
        width: 110px;
        height: 21px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        @include font_poppins_regular;
        color: #e3deff;
      }
    }
  }
}

.wallet_content_puzzle {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  align-items: center;

  &_market {
    width: 235px;
    height: 45px;
    box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background: #212332;

    &_first {
      width: 50%;
      //border: 1px solid red;
      &_text {
        width: 60px;
        height: 12px;
        @include font_poppins_regular;
        font-style: normal;
        font-weight: bold;
        font-size: 8px;
        line-height: 12px;
        color: $color-white-grey;
        padding-left: 15px;
      }

      &_text-inactive {
        width: 85px;
        height: 12px;
        font-style: normal;
        font-weight: 700;
        font-size: 8px;
        line-height: 12px;
        color: #626167;
        @include font_poppins_regular;
        padding-left: 15px;
      }

      &_second {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 8%;
        color: $color-white-grey;
        appearance: none;
        background: transparent;
        border: none;
        outline: none;
      }

      &_second::placeholder {
        color: #626167;
      }
    }

    &_first:hover {
      span {
        color: $color-white-grey;
      }
      input::placeholder {
        color: $color-white-grey;
      }
    }
    &_back {
      width: 50%;
      background: #171923;
      border-radius: 10px;
    }
  }
}
.wallet_dollar_one {
  height: 154px;
}
.wallet_dollar_all {
  height: 30px;
}
.wallet_dollar {
  width: 182px;
  //height: 39px;

  background: $color-wallet;
  //box-shadow: 0px 11px 11px rgba(0, 0, 0, 0.02);
  box-shadow: $shadow_wallet_dollar;
  border-radius: 10px;
  //margin-left: 300px;
  padding-top: 10px;
  justify-content: center;
  background: $background-darker;
  z-index: 1;

  &_item {
    padding-top: 5px;
  }

  &_first {
    margin-left: 7px;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 11px;
  }
}

.img_wallet {
  width: 20px;
  height: 20px;
}

.dollar_text {
  width: 92px;
  height: 21px;
  @include font_poppins_regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  //color: #0e1446;
  color: $color-blue-dark;
  //padding-top: 5px;
  margin-left: 5px;
  color: $color-white-grey;
}

.down_wallet {
  margin-left: 20px;
  width: 18px;
  height: 18px;
  background: #212332;
  border-radius: 50px;
}

.img_check {
  margin-left: 13px;
  width: 18px;
}

.img_down_wallet {
  margin-left: 3px;
}

.container_puzzle_count {
  margin-left: 2px;
}

.puzlles_count {
  width: auto;
  height: 40px;
  background: $background-darker;
  //box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.12);
  //box-shadow: $shadow_puzzle_count;
  border-radius: 10px;

  //font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: $color-ligther-count;
  display: flex;
}

.btn_less {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-image: url(../../../Assets/vectors/less_.svg);
  background-repeat: no-repeat;
  background-position: 50%;
}

.btn_less:hover {
  background-image: url(../../../Assets/vectors/purchase/less_active.svg);
}

.btn_plus {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  float: right;
  margin-left: 23px;
  background-image: url(../../../Assets/vectors/add_round.svg);
  background-repeat: no-repeat;
  background-position: 50%;

}

.btn_plus:hover {
  background-image: url(../../../Assets/vectors/purchase/add_round_active.svg);
}

.btn {
  width: 40px;
  height: 40px;
  //opacity: 0.5;
  border: none;
  //background: $color-violet-dark-btn;
  box-shadow: $shadow_puzzle_count;
  color: $color-white;
  //background: rgb(23, 25, 35);
  // opacity: 0.3;
  background-color: #212332;
}

.number_couted {
  @include font_poppins_regular;
  width: 59px;
  height: 29px;
  margin-left: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: $color-white-grey;
  outline: none;
  background: transparent;
  border: none;
  text-align: center;
}

.order_purchase {
  //padding-top: 30px;
  //margin: 20px;
}

.watch_content {
  display: flex;
  align-items: center;
}

.watch_content_img {
  width: 60px;
  height: 52.5px;
  border-radius: 8px;
}

.watch_all_text {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.watch_all_text_first {
  width: 101px;
  height: 18px;
  @include font_poppins_regular;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  text-transform: uppercase;

  color: $color-gray-watch;

  //opacity: 0.6;
}

.watch_all_text_second {
  width: 192px;
  height: 19px;
  //font-family: Open Sans;
  @include font_poppins_regular;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: $color-white-grey;
}

.watch_text {
  margin-left: 10px;
}

.content_three {
  //margin: 20px;
}

.item_order {
  display: flex;
  justify-content: space-between;

  //padding-top: 19px;
}

.item_order_first {
  width: 50%;
  height: 14px;
  //font-family: Open Sans;
  @include font_poppins_regular;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: $color-white-grey;
  //color: $color-white;
}
.item_order_second {
  width: 50%;
  height: 18px;
  text-align: right;
  //font-family: Open Sans;
  @include font_poppins_regular;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: $color-white-grey;
  //color: $color-white;
}

.total {
  width: 50%;
  height: 25px;
  //font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: $color-white-grey;
  //color: $color-white;
}

.total_value {
  width: 50%;
  height: 33px;
  //font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: $color-white-grey;
  //color: $color-white;
  text-align: right;
}

.content_four {
  display: flex;
  justify-content: space-between;
  //margin: 20px;
}

.line {
  //border: 0.5px solid $color-gray-line;
  //border: 0.5px solid $background-dark;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content_order_all {
  margin: 20px;
}

.espace {
  padding-top: 19px;
}

.btn_confirmed {
  cursor: pointer;
  border: none;
  width: 204px;
  height: 47px;
  background: $gradient_btn_confirm;
  /* btn */

  box-shadow: $shadow_confirm;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  padding-top: 6px;
}

.btn_confirmed:hover {
  background: rgba(96, 80, 222, 1);
}

.img_confirmed {
  width: 30px;
  height: 30px;
}

.text_confirmed {
  height: 27px;
  @include font_poppins_regular;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: $color-lighter-confirm;
  margin-left: 10px;
  cursor: pointer;
}

.btn_confirmed_content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_btn {
  display: flex;
  justify-content: center;
}

.line_transaction {
  //border: 1px solid $color-gray-line;
  //border: 0.5px solid $background-dark;
  margin: 20px;
}

/******************** stats ************************/

.stats {
}
.stats_content {
  margin: 20px;
}

.stat_title {
  width: 83px;
  height: 18px;
  @include font_poppins_regular;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;

  //color: #d8ccdd;
  color: $color-stat-title;
}

.item_stats {
  display: flex;
}

.text_position_right {
  width: 50%;
  text-align: right;
}

.espace_stat_firsts {
  padding-top: 30px;
}
.espace_stats {
  padding-top: 20px;
}

.my_part {
  width: 50%;
  height: 25px;
  //font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: $color-white-grey;
  //color: $color-white;
}
.percent_part {
  width: 50%;
  height: 33px;
  //font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: $color-white-grey;
  //color: $color-white;
  text-align: right;
}

/****************************** billing *************************/

.billing_content {
  margin: 20px;
}

.billing_first {
  display: flex;
  padding-top: 20px;
}

.billing_first_text {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.billing_first_img {
  width: 30px;
  height: 30px;
}

.billing_first_text_name {
  height: 12px;
  @include font_poppins_regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: $color-white-grey;
  //color: $color-white;
  mix-blend-mode: normal;
  opacity: 0.8;
}

.billing_first_text_last_name {
  height: 15px;
  @include font_poppins_regular;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.5px;
  color: $color-white-grey;
  //color: $color-white;
  mix-blend-mode: normal;
}

.value_adress {
  margin-left: 200px;
}

.espace_billing_two {
  padding-top: 23px;
}

.line_billing {
  //border: 0.5px solid $color-gray-line;
  border: 0.5px solid $background-dark;
  margin-top: 23px;
  margin-bottom: 23px;
}

.btn_billing {
  width: 133px;
  height: 31px;
  background: $color-wallet;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.puzzle_title_btn {
  width: 72px;
  height: 21px;
  @include font_poppins_regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: $color-blue-light-btn;
  margin-left: 10px;
}
.container_btn_billing {
  padding-top: 20px;
}

.container_btn_add {
  display: inline-flex;
  width: 150px;
  height: 40px;
  background: $color-btn-add;
  box-shadow: $shadow_puzzle_count;
  border-radius: 10px;
  margin-left: 330px;

  //font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  //color: #f9edff;
}

.img_btn_plus {
  padding-top: 6px;
}

.img_btn_less {
  padding-bottom: 2px;
}
.container_number_counted {
  padding-top: 7px;
}

.font {
  @include font_poppins_regular;
}


.content_hover:hover {
  span {
    color: $color-pure-purple !important;
  }
}