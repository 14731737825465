@import '../../../../Style/mixins';

.NewFAQ {
  .form {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      // align-items: center;
      .container-input {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          margin: 1% 1%;

          .label {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 17px;
              color: rgb(150, 149, 170);
              @include font_poppins_regular;
          }

          .input {
              @include font_poppins_regular;
              outline: none;
              width: 98%;
              resize: none;
              height: 200px;
              background: #212332;
              box-shadow: 0px 9px 23px rgba(45, 43, 43, 0.15);
              border-radius: 10px;
              border: none;
              margin-top: 20px;
              padding: 2%;
              font-size: 1.1em;
              color: #d8d7e1;
          }
      }



  }
  .buttons{
    display: flex;
    gap: 1em;
    .button {
        border: none;
        align-items: center;
        height: 47px;
        background: linear-gradient(292.53deg, #9381FF 31.56%, rgba(147, 129, 255, 0.75) 97.92%);
        box-shadow: 0px 9px 23px rgba(10, 10, 10, 0.15);
        border-radius: 10px;
        margin-top: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        @include font_poppins_regular;
        color: #f9edff;
        padding: 0 20px;
        margin-left: 12px;
    }

  }

  

}