@import "../../../../../Style/_mixins.scss";
@import "../../../../../Style/variables";


.Blocked {
  @include font_poppins_regular;
  color: #D8D7E1;
  display: flex;
  align-items: center;
  gap: 1em;
  padding: .8em 1em;
  border-radius: 2em;
  background-color: #1C1E29;
  cursor: pointer;

  .profile-pic {
    background-image: url(../../../../../Assets/img/pp.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;

  }
}