.error-message{
    color: red;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Ajustez la hauteur en fonction de vos besoins */
    overflow: hidden;
    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // height: 100vh;
        display: flex;
        flex-direction: column; /* Placez le contenu verticalement */
         align-items: center; /* Centrez le contenu horizontalement */
        justify-content: center; /* Centrez le contenu verticalement */
        background-image: url('../../../Assets/img/puzzle_iconic-v12.mp4');
        // background-size: cover;
        //  background-repeat: no-repeat;
        //  background-position: center center;
        z-index: 1; /* Assurez-vous que le contenu est au-dessus de la vidéo */
        /* Ajoutez d'autres styles de positionnement et de mise en page pour votre contenu ici */
    }
}
// .mg-form {
//     margin-top: 1.5% !important;
// }

.content-form{
    background-color: #1F202D;
    padding: 20px;
    border-radius: 20px;
    border: solid #C59FD6 2px;
    // margin-top: 80%; 
    .connection_logo{
        margin-top: 0px !important
    }
}

.test {
    width: 100vh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}