.avatar_hover {
  width: 100px;
  height: 100px;
  /*border-radius: 90px;
  position: absolute;
  z-index: 1;
  cursor: pointer;*/
}

.avatar_backgroung {
  width: 100px;
  height: 100px;
  border-radius: 90px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.avatar_input {
  display: none;
}

.avatar_backgroung:hover {
  background: url("../../../../Assets/vectors/Profile/edit_avatar_hover.svg")
    no-repeat;
}

/*.avatar_hover:hover {
  background: url("../../../../Assets/vectors/Profile/edit_avatar_hover.svg")
    no-repeat;
}*/

.profile {
  &_path {
    margin-left: 40px;
    margin-top: 60px;

    &_img {
      margin-bottom: 10px;
      margin-top: -35px;
      position: absolute;
    }

    &_current {
      @include font_poppins_regular;
      font-size: $font-h2-h3;
      color: $color-text-purple;
      font-weight: 700;
    }

    &_info {
      @include font_poppins_regular;
      font-size: $font-legend-info;
      color: $color-text-purple;
      margin-top: 10px;

      span {
        font-weight: 700;
      }
    }
  }

  &_container {
    margin: 25px;

    &_about {
      width: 740px;
      height: 270px;
      margin: 8px;
      background: $background_profile;
      border-radius: 20px;
      box-shadow: $shadow_account;

      &_left {
        min-width: 275px;
        max-width: 275px;
        margin: 25px;

        &_avatar {
          margin-top: 30px;
          margin-bottom: 20px;

          &_img {
            box-shadow: $shadow_popup;
            width: 100px;
            height: 100px;
            border-radius: 90px;
            border: 3px solid $color-black;
          }
          &_verified {
            margin-top: 70px;
            margin-left: 44px;
            position: absolute;
            z-index: 1;
          }
        }

        &_name {
          text-align: center;
          @include font_poppins_regular;
          font-size: $font-h2-h3;
          font-weight: 700;
          color: $color-white;
        }
        &_pseudo {
          text-align: center;
          @include font_poppins_regular;
          font-size: $font-subtitle;
          color: $color-white;
        }
      }

      &_hr {
        width: 1px;
        height: 171px;
        margin-top: 50px;
        background-color: $color-grey-purple;
        border: none;
      }

      &_right {
        margin: 25px;
        padding-top: 25px;
        min-width: 363px;
        max-width: 363px;

        &_title {
          margin-left: 25px;
          @include font_poppins_regular;
          font-size: $font-body-2;
          font-weight: 600;
          color: $color-white;
          margin-bottom: 10px;
          text-align: left;
        }
        &_description {
          min-height: 65px;
          max-height: 65px;
          margin-left: 25px;
          text-align: left;
          @include font_poppins_regular;
          font-size: $font-legend-info;
          color: $color-white;
        }

        &_textarea {
          min-height: 57px;
          max-height: 57px;
          min-width: 280px;
          max-width: 280px;
          margin-left: 25px;
          text-align: left;
          @include font_poppins_regular;
          font-size: $font-legend-info;
          color: $color-white;
          background-color: $color-grey-purple;
          border: none;
          resize: none;
          overflow: hidden;
          border-radius: 5px;
        }

        &_social {
          margin-top: 10px;
          margin-left: 25px;

          &_add {
            width: 35px;
            height: 35px;
            border: 1px solid $color-light-purple2;
            border-radius: 25px;
            margin-right: 20px;
            cursor: pointer;

            &_img {
              padding: 5px;
            }
          }

          &_content {
            text-align: left;
            @include font_poppins_regular;
            font-size: $font-legend-info;
            color: $color-white;
          }

          &_title {
            margin-left: 25px;
            margin-top: 20px;
            @include font_poppins_regular;
            font-size: $font-body-2;
            font-weight: 600;
            color: $color-white;
            text-align: left;
          }

          &_logo {
            width: 37px;
            height: 37px;
            background: $color-light-purple2;
            border-radius: 25px;
            margin-right: 20px;
          }
          &_logo:nth-child(1),
          &_logo:nth-child(2) {
            img {
              padding: 7px;
              margin-top: 2px;
            }
          }
          &_logo:nth-child(3) {
            img {
              padding: 7px;
              margin-top: 2px;
            }
          }
          &_logo:nth-child(4) {
            img {
              padding: 6px;
              margin-top: 2px;
            }
          }
        }

        &_edit {
          @include font_poppins_regular;
          cursor: pointer;
          margin-left: 70%;
          //margin-top: 3%;
          margin-top: -3%;
          color: $color-white-grey;
        }

        &_add_link {
          cursor: pointer;
          margin-left: 35%;
          margin-top: 3%;
        }

        &_linkrequest {
          float: right;
          width: 160px;
          height: 36px;
          background: $background-dark;
          border-radius: 20px;
          margin-top: 5%;
          margin-right: -5%;

          &_text {
            @include font_poppins_regular;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: $color-white-grey;
            margin-right: 10%;
          }

          &_accept {
            margin-right: 2%;
            cursor: pointer;
          }

          &_refuse {
            cursor: pointer;
          }
        }
      }
    }
  }

  &_message {
    color: white;
  }
}

.linked {
  margin-top: 5%;
  margin-left: 80%;
}
