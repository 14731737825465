.my-swiper {
  position: relative;
  // width: 1139px; 
  // height: 384px;
  &:before {
    content: '';
    padding-bottom: 33.713784%;
    display: block;
  }
  .swiper-truc{
    position: absolute;
    inset: 0;
    border-radius: 30px;
  }
  .swiper-pagination-bullet{
    background: transparent;
    padding: 3px;
    margin: 0 5px;
    box-shadow: 0 0 0 1px white;
    opacity: 1;
    &.swiper-pagination-bullet-active{
      background: white;
    }
  }
  .swiper-button-prev, .swiper-button-next{
    color: white;
    &:hover{
      color: #007aff;
      scale: 1.2;
    }
  }
  .swiper-slide{
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.home-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // border-radius: 30px;
  margin-bottom: 30px;

  &:before {
      content: '';
      padding-bottom: 33.713784%;
      display: block;
  }
}
