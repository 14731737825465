.bottom_add{
    margin-bottom: 15px;
}
.dropdown_currency {
    max-height: 150px;
    overflow: auto;
    overflow-x: hidden;
    padding-top: 5px;
    border-radius: 4px;
  }
  
  .dropdown_currency::-webkit-scrollbar {
    width: 8px;
  }
  
  .dropdown_currency::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 4px;
  }
  
  .dropdown_currency::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  .dropdown_currency::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  