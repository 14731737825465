.photo-upload-container {
    border-radius: 10px;
    background-color: rgb(21, 23, 33);
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.img-bottom{
    margin-top: 5px;
}

.photo-title{
    font-family: 'Poppins-Regular';
    color: aliceblue;
    margin: 5px;
}

