@import "../../../../Style/_mixins.scss";
@import "../../../../Style/_variables.scss";

.Groups {
  @include font_poppins_regular;
  color: $color-text-purple;
  display: flex;
  flex-direction: column;


  .group-name-wrapper{
    display: flex;
    flex-direction: column;
    gap: .5em;
    margin: .4em 0;
    label{
      font-weight: lighter;
    }
    input{
      @include font_poppins_regular;
      padding: .8em 1.5em;
      border-radius: .5em;
      font-size: 1em;
      color: $color-light-pink;
      border: none;
      background-color: #212332;
      outline: none;
      &::placeholder{
        color: #86849D;
        font-weight: bold;

      }
    }
  }
  .group-title {
    margin: 50px 0 20px 0; 
    font-weight: lighter;
    display: flex;
    justify-content: space-between;

    span:last-child{
      cursor: pointer;
    }
  }

  .users-list{
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    gap: .5em;
    max-height: 50vh;
    overflow-y: auto;

  }
  
  .decision-buttons{
    margin: 30px 0;
    display: flex;
    justify-content: center;
    gap: .8em;
  }


}