.shadow {
    text-shadow: 8px 10px 5px rgba(0, 0, 0, 0.5)
}

.car-description {
    position: absolute;
    bottom: 20px; /* Ajustez la position verticale selon vos besoins */
    left: 20px; /* Ajustez la position horizontale selon vos besoins */
    background-color: rgba(255, 255, 255, 0.8); /* Fond semi-transparent */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: #333; /* Couleur du texte */
    max-width: 300px; /* Largeur maximale du contenu */
  }
