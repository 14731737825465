@import "../../../../Style/_mixins.scss";
@import "../../../../Style/_variables.scss";

.users-list {
  .user-item-container {
    padding: 1em .8em;
    background-color: #212332;
    display: flex;
    justify-content: space-between;
    border-radius: .7em;

    .user-item {

      display: flex;
      align-items: center;
      gap: 1em;

      .profile-pic {
        background-image: url(../../../../Assets/img/User/pp.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 40px;
        height: 40px;
      }
    }

    .check-user {
      background-repeat: no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;
      cursor: pointer;
      align-self: center;
      &.unchecked {
        background-image: url(../../../../Assets/vectors/settings/circle.svg);
      }
      &.checked{
        background-image: url(../../../../Assets/vectors/settings/circle-selected.svg);
      }
    }

    
  }
}