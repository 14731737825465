@import "../../../Style/mixins";
@import "../../../Style/variables";

.modal {
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  &_content {
    background-color: $background-color-dark;
    border-radius: .8em;
    padding: 2em;
    width: 500px;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &_header {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
    &_title {
      @include font_poppins_regular;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      // line-height: 18px;
      color: $color-light-pink;
    }
    &_close {
      display: inline-block;
      cursor: pointer;
      height: 24px;
      width: 24px;
      border-radius: 12px;
      background-color: $color-light-pink;
      &_sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }
  }
  &_body {
    width: 100%;
  }
}
