@import "../../../Style/_mixins";
@import "../../../Style/_variables.scss";

.FAQ {

  margin: 0;

  .faq_search {
    border-radius: 2em;
    padding: 3em;
    margin: 7em 0;
    background: linear-gradient(90.99deg, rgba(22, 25, 43, 1) 11.45%, rgba(34, 23, 52, 1) 81.9%);
    @include font_poppins_regular;
    color: $color-faq-search;

    .search_label {
      margin-bottom: 3em;

      h3 {
        margin: 0;
        margin-bottom: 1em;
      }
    }

    .search_bar {
      position: relative;

      .search_icon {
        position: absolute;
        top: 1.1em;
        left: 1.3em;
        background-image: url(../../../Assets/img/search.png);
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
      }

      input {
        padding: 1.5em;

        width: 300px;
        background-color: rgba(44, 47, 70, 1);
        border-radius: 2em;
        border: none;
        outline: none;
        color: $color-faq-search;
        text-indent: 25px;

        &::placeholder {
          color: $color-faq-search;
          font-size: 1em;
          @include font_nunito_regular;
        }
      }
    }
  }

  .faq_menu {
    @include font_poppins_regular;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .faq_sort {
      font-size: 18px;
      color: $color-faq-sort;
      align-self: center;
    }

    .add_topic {
      background-color: rgba(27, 29, 41, 1);
      border-radius: 3.7em;
      color: $color-faq-search;
      padding: 0.7em 1.5em;
      cursor: pointer;

      .add_label {
        &::before {
          content: "+";
          margin-right: 10px;
          font-size: 25px;
          vertical-align: middle;
        }
      }
    }
  }
  

  
}