.wood_border {
  background: url("../../../../Assets/img/wood_border.png") no-repeat;
  width: 220px;
  height: 220px;
  border-radius: 20px;
}
.silver_border {
  background: url("../../../../Assets/img/silver_border.png") no-repeat;
  width: 220px;
  height: 220px;
  border-radius: 20px;
}
.crystal_border {
  background: url("../../../../Assets/img/crystal_border.png") no-repeat;
  width: 220px;
  height: 220px;
  border-radius: 20px;
}
.pink_border {
  background: url("../../../../Assets/img/pink_border.png") no-repeat;
  width: 220px;
  height: 220px;
  border-radius: 20px;
}
.gold_border {
  background: url("../../../../Assets/img/gold_border.png") no-repeat;
  width: 220px;
  height: 220px;
  border-radius: 20px;
}

.profile {
  &_gallery {
    background: $color-grey-purple;
    border-radius: 20px;
    width: 1120px;
    height: auto;
    margin: 33px;
    &_btn {
      justify-content : flex-end;
      padding-right: 2%;
    margin-bottom: 27px;
      &_content {
        background: linear-gradient(292.53deg, #9381ff 31.56%, #9381ff 97.92%);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    //box-shadow: 0px 9px 23px rgb(0,0,0,0.15);
    color: #f9edff;
    @include font_poppins_regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    height: 27px;
    line-height: 21px;
    margin-top: -11px;
    width: 110px;
      }
      
    }
    &_content {
      width : 100%;
      &_all-tab {
        width : 81%;
      }
    }
    &_icon {
      align-items: center;
      /* justify-content: center; */
      display: flex;
      margin-top: 20px;
    }
    &_tab {
      height: 60px;
      &_content {
        //margin: 30px;
        margin: 30px 12px 30px 30px;
        @include font_poppins_regular;
        color: $color-text-purple;
        font-size: $font-body-2;
        font-weight: 600;
        cursor: pointer;
        :hover {
          color: $color-pure-purple;
        }
        img {
          margin-bottom: 2%;
        }

        &_allPuzzles {
          color: $color-pure-purple;
        }

        &_counter {
          color: rgba(76, 82, 112, 1);
        }
      }

      &_selected {
        color: $color-pure-purple;
      }
    }

    &_hr {
      width: 1060px;
      height: 2px;
      border: none;
      background-color: $color-black;
    }
    &_header {
      @include font_poppins_regular;
      color: $color-text-wallets;
      margin-top: 40px;

      &_title {
        font-size: $font-h2-h3;
        margin-bottom: 10px;
      }
      &_value {
        font-size: $font-body-3;
        text-transform: uppercase;
      }
    }

    &_body {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      //width: 1060px;
      height: auto;
      //margin-left: 30px;
      &_card {
        margin-left: 10px;
        padding: 10px;
        margin-top: 15px;
        &_view {
          &_img {
            width: 210px;
            height: 210px;
            border-radius: 20px;
            margin: 5px;
          }

          &_cover {
            background-color: rgba(137, 137, 137, 0.8);
            backdrop-filter: blur(45px);
            filter: blur(2px);
            position: relative;
            z-index: 1;
            //margin-top: -38%;
            margin-top: -30%;
            margin-left: 0px;
            margin-left: 5px;
            height: 75px;
            width: 210px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
          }
          &_title {
            @include font_poppins_regular;
            font-size: $font-body-3;
            color: $color-white_puzzle;
            text-shadow: $light_shadow;
            text-align: center;
            font-weight: 700;

            position: relative;
            z-index: 2;
            margin-top: -26%;
            margin-right: 13%;
          }
          &_category {
            @include font_poppins_regular;
            font-size: $font-legend-info;
            color: $color-white_puzzle;
            font-style: italic;
            text-shadow: $light_shadow;
            position: relative;
            z-index: 2;
            margin-left: 18px;
            margin-top: 2px;
          }

          &_footer {
            @include font_poppins_regular;
            font-size: $font-legend-info;
            height: 20px;
            position: relative;
            z-index: 2;
            margin-top: 7px;

            &_stats {
              color: $color-text-purple;
              margin-left: -8%;
              margin-top: 1%;

              &_like {
                cursor: pointer;
                margin-left: 15px;
                &_content {
                  margin: 4px;
                }
              }
              &_comment {
                cursor: pointer;
                margin-left: 10px;
                &_content {
                  margin: 4px;
                }
              }
            }
            &_input {
              position: relative;
              top: 11%;
              left: 38%;
            }
            &_percent {
              color: $color-light-green;
              background: #555555bd;
              border-radius: 20px;
              width: auto;
              height: 20px;
              position: relative;
              top: 11%;
              left: 5%;
              padding-right: 5px;

              &_icon {
                width: 15px;
                height: 15px;
                margin-top: 2px;
                margin-left: 4px;
              }
              &_content {
              }
            }
          }
          &_more {
            @include font_poppins_regular;
            text-align: center;
            width: 218px;
            height: 218px;
            margin-bottom: 8%;
            margin-left: -8%;
            background: $background-dark;
            box-shadow: 0px 20px 45px -26px rgba(0, 0, 0, 0.5);
            border-radius: 15px;

            &_title {
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 18px;
              color: rgb(80, 86, 116);
              margin-right: 50%;
              margin-top: 5%;
            }

            &_ammount {
              font-style: normal;
              font-weight: 500;
              font-size: 32px;
              line-height: 42px;
              letter-spacing: 0.04em;
              color: $color-white-grey;
              margin-top: 5%;
              margin-right: 40%;
              span {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 125.2%;
              }
            }

            &_button {
              width: 90%;
              display: flex;
              
              height: 48px;
              background: #1c1d29;
              border-radius: 45px;
              margin-top: 35%;
              margin-left: 5%;
              

              &_img {
                width: 58px;
                height: 58px;
              }

              &_text {
                &_1 {
                  font-style: italic;
                  font-weight: normal;
                  font-size: 9px;
                  line-height: 109%;
                  color: $color-light-pink;
                  margin-right: 25%;
                }

                &_2 {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 109%;
                  color: $color-white-grey;
                }
              }
            }

            &_button:hover {
              background: black;
              div {
                color: $color-pure-purple;
              }
            }

            &_icon {
              margin-top: 10px;
              margin-left: 10px;

              width: 125px;
              height: 125px;
            }

            &_content {
              margin-top: 10px;
              color: $color-text-wallets;
              font-size: $font-legend-info;
            }
            &_next {
              margin-top: 5px;
              color: $color-white;
              font-size: $font-body-3;
            }
          }

          &_avatar {
            position: relative;
            z-index: 1;
            margin-top: -96%;
            margin-left: 180px;
            &_img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
