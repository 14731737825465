.transferin {
  @include font_poppins_regular;
  &_wallet {
    @include font_poppins_regular;
    width: 571px;
    height: 76px;
    box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background: $color-borders;
    padding: 5px;
    margin-bottom: 1.5%;

    &_devise {
      margin-left: 37%;
      width: 32%;
      display: flex;
      align-items: center;
      &_img {
        width: 20px;
        margin-right: 30%;
      }
      &_img-down {
        margin-left: 4px;
      }
      &_text {
        width: 19px;
        height: 18px;
        font-weight: 600;
        font-size: 10px;
        line-height: 18px;
        color: #d8d7e1;
        @include font_poppins_regular;
      }
    }
    &_card {
      //width: 350px;
      width: 571px;
      height: 66px;
      background: $color-borders;
      border-radius: 15px;
      cursor: pointer;
      margin-right: 1%;
      &_text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        width: 187px;
        justify-content: flex-start;
      }
      &_change {
        margin-left: 39%;
      }
      &_down {
        //margin-left: 50%;
        //margin-left: 45%;
        //margin-left: 146px;
      }
    }

    &_crypto {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $color-light-pink;
      width: 250px;   

      &_change {
        margin-left: 40%;
        cursor: pointer;
      }
    }

    &_add {
      width: 569px;
      height: 77px;
      background: $color-borders;
      border-radius: 15px;
      cursor: pointer;
      margin-right: 1%;

      &_img {
        margin-right: 3%;
        margin-left: 2%;
      }

      &_text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &_add:hover {
      background: $background-dark;
    }

    &_inputCrypto {
      margin-left: 7% !important;
    }
    &_input {
      @include font_poppins_regular;
      width: 200px;
      height: 66px;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 33px;
      color: $color-white-grey;
      background: transparent;
      border: none;
      text-align: center;
      border-radius: 15px;
      margin-left: 1%;
    }

    &_input:hover {
      background: $background-dark;
    }

    &_input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &_popup {
    @include font_poppins_regular;
    color: $color-white-grey;
    width: 320px;
    height: 357px;
    box-shadow: 0px 19px 72px #000000;
    border-radius: 20px;
    background: $background-dark;
    position: absolute;
    z-index: 10;
    align-self: center;
    top: 20%;

    &_titles {
      margin-top: 10%;
      margin-left: 10%;

      &_card {
        margin-right: 5%;
      }
    }
    &_inputs {
      margin-top: 10%;
      input:focus {
        @include font_poppins_regular;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: $color-white-grey;
      }

      input::placeholder {
        @include font_poppins_regular;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: $color-grey-placeholder;
      }

      input:focus::placeholder {
        @include font_poppins_regular;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: $color-white-grey;
      }

      &_text {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 18px;
        margin-bottom: 2%;
        margin-left: 2%;
        width: max-content;
      }

      &_title {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
      }

      &_checkbox {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 18px;
        margin-right: 25%;
        margin-bottom: 10%;
      }

      &_cardnumber {
        margin-bottom: 10%;
        margin-left: 8%;

        &_input {
          width: 260px;
          height: 35px;
          background: $background-color-light-black;
          border-radius: 8px;
          border: none;
          padding-left: 3%;
          color: $color-white-grey;
        }
      }

      &_date {
        margin-left: 8%;
        &_arrow {
          margin-left: 10%;
        }

        &_text:hover {
          color: $color-white-grey !important;
        }
        select {
          @include font_poppins_regular;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: $color-grey-placeholder;
          padding-left: 3%;
        }

        select:focus {
          @include font_poppins_regular;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: $color-white-grey;
        }

        &_month {
          width: 40px;
          height: fit-content;
          background: $background-color-light-black;
          border-radius: 8px;
          border: none;
          margin-right: 5%;
          position: absolute;

          div {
            @include font_poppins_regular;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: $color-grey-placeholder;
            padding-left: 3%;
            height: 37px;
          }

          div:focus,
          div:hover {
            @include font_poppins_regular;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: $color-pure-purple;
          }
        }

        &_year {
          width: 70px;
          height: fit-content;
          background: $background-color-light-black;
          border-radius: 8px;
          border: none;
          position: absolute;
          right: 55%;
          div {
            @include font_poppins_regular;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: $color-grey-placeholder;
            padding-left: 3%;
            height: 37px;
          }

          div:focus,
          div:hover {
            @include font_poppins_regular;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: $color-pure-purple !important;
          }
        }
      }

      &_cvc {
        margin-right: 8%;
        margin-bottom: 10%;
        &_input {
          width: 100px;
          height: 35px;
          background: $background-color-light-black;
          border-radius: 8px;
          border: none;
          padding-left: 3%;
          color: $color-white-grey;
        }
      }
    }

    &_select_method {
      margin: 5%;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $color-light-pink;

      img {
        cursor: pointer;
      }
    }

    &_card {
      margin-left: 5%;
      margin-top: 3%;
      margin-right: 5%;
      &_title {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        color: $color-white-grey;

        
      }

      &_add {
        width: 280px;
        height: 55px;
        box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
        background: $color-borders;
        margin-left: 1%;
        margin-top: 2%;
        cursor: pointer;

        &_img {
          margin-left: 2%;
          margin-right: 3%;
          
        }
      }

      &_added {
        width: 280px;
        height: 55px;
        box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
        background: $color-borders;
        margin-left: 1%;
        margin-top: 2%;
        cursor: pointer;

        &_img {
          margin-left: 2%;
          margin-right: 3%;
        }

        &_title {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: $color-light-pink;
        }

        &_text {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: $color-white-grey;
        }

      }
    }

    &_other {
      margin-left: 5%;
      margin-top: 10%;
      &_title {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        color: $color-white-grey;
      }
      
    }
  }

  &_wallets {
    width: 571px;
    height: 76px;
    box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background: $color-borders;
    padding: 5px;
  }

  &_crypto {
    @include font_poppins_regular;
    width: 581px;
    height: 346px;
    box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background: $color-borders;
    margin-top: 4%;

    &_header {
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 2%;
      &_instructions {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: $color-white;
        margin-bottom: 3%;
      }
    }

    &_title {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: $color-white-grey;
      margin-left: 5%;
    }

    &_content {

      &_container {
        width: 380px;
        height: 40px;
        box-shadow: 0px 9px 23px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background: $background-dark;
        padding-left: 3%;
        margin-bottom: 3%;
        margin-left: -5%;

        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: $color-white-grey;
      }

      img {
        cursor: pointer;
      }
    }
  }

}

.no_margin_right {
  margin-right: 22%;
}

.purple {
  color: $color-pure-purple;
}

.buttons_adjust {
  margin-bottom: 24.5%;
}

.whitegrey {
  color: $color-white-grey !important;
}
.transfer_in_img {
  width: 45px;
}

.to_margin {
  margin-top: 4%;
}

.new_wallet_size {
  width: 328px;
  box-shadow: none;
  align-self: flex-start;
  margin-right: 1%;
}

.new_wallet_height {
  height: 45px;
}
.dropdown_currency{
  max-height: 123px;
  overflow: scroll;
  overflow-x: hidden;
}

.checked_margin {
  margin-left: 50%;
}

.expand_margin {
  margin-left: 64%;
}

.page_title_transferin {
  width: 1000px;
  margin-bottom: 10%;
}

.add_card_margin {
  margin-top: -7%;
}

