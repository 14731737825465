.row{
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px;
}
.row .col-md-4{
    width: 25%;
    margin-bottom: 40px;
}
.my-swiper:before{
    padding-bottom: 0!important;
}
.market_puzzle_img{
    object-fit: cover;
}
.padding{
    margin-bottom: 80px;
}