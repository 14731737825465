.page_title {
  margin-left: 25px;
  @include font_poppins_regular;
  font-size: $font-h2-h3;
  //color: $color-purple;
  color: $color-white;
  font-weight: 700;
  margin-top: 140px;
}
.page_title-puzzle{
  margin-left: 70px;
  @include font_poppins_regular;
  font-size: $font-h2-h3;
  //color: $color-purple;
  color: $color-white;
  font-weight: 700;
  margin-top: 140px;
}

.account {
    margin: 20px;

    &_currencie {
        @include font_poppins_regular;
        width: 350px;
        // height: auto;
        //background: $color-white;
        background: $color-grey-purple;
        box-shadow: $shadow_currency;
        border-radius: 18px;
        &_name {
          //color: $color-lighter-purple;
          color: $color-pure-purple;
          font-weight: 700;
          padding: 20px;
        }
        &_underscore {
          //background-color: $color-lighter-purple;
          background-color: $color-pure-purple;
          border: none;
          width: 12px;
          height: 1.5px;
          float: left;
          margin-left: 20px;
          margin-top: -15px;
        }
    
        &_balance {
          
          width: 300px;
          background: $color-light-purple2;
          border-radius: 8px;
          margin-left: 7%;
          margin-right: 16%;
          margin-top: 20px;
          margin-bottom: 10px;
          padding-bottom: 5px;

            &_div {
              background: none;
              border-radius: 10px;
              //width: 153px;
              //margin-left: 4.5%;
              //position: absolute;
              width : 228px;
              margin-left : 12.5%;
            }

            &_name {
                font-size: $font-body-light;
                color: $color-light-pink;
                padding: 20px;
                font-weight: 700;
            }
            &_amount {
                backdrop-filter: blur(4px);
                font-size: $font-h2;
                color: $color-white;
                text-align: center;
                border-radius:  10px 10px 0px 0px;
                
                span {
                  font-size: $font-menu-2;
                  font-weight: 700;
                }

                img {
                  margin-left: 25px;
                  cursor: pointer;
                  width: 24px;
                }
            }

            &_expand {
              backdrop-filter: blur(500px);
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              line-height: 13px;
              color: $background-color-white;
              border-radius: 0px 0px 10px 10px;
              padding: 10px;
              width: 133px;

              &_text {
                margin-top: 10px;
                cursor: pointer;
              }
            }
        }
    
        &_transfer {
          //padding-top: 30px;
          margin-top: 12%;

          &_global {
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 5px;
            width: 48px;
            height: 48px;
            //background: $color-white;
            background: $color-black;
            box-sizing: border-box;
            box-shadow: $shadow_actions;
            border-radius: 15px;
            cursor: pointer;

            img {
              padding: 1px;
            }

            &_name {
                display: inline-flex;
                width: 100%;
                text-align: center;
                @include font_poppins_regular;
                font-size: $font-legend-info;
                font-weight: 700;
              
                &_text {
                    white-space: nowrap;
                    color: $color-white;
                    font-size: 10px;
                    line-height: 15px;
                    margin-right: 1%;
                }
            }
            &_name > div:nth-child(1) {
              margin-left: 48px;
            }
            &_name > div:nth-child(2) {
              margin-left: 14px;
            }
            &_name > div:nth-child(3) {
              margin-left: 16px;
            }
          }
        }
      }
}
