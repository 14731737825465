@import "../../../Style/_mixins.scss";
@import "../../../Style/_variables.scss";

.controls {
  @include font_poppins_regular;
  font-style: normal;
  font-weight: normal;
  // font-size: 10px;
  color: $color-light-pink;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  gap: .5em;
  &_input {
    padding: .8em 1.5em;
    border-radius: 8px;
    font-size: 1.2em;
    background: $background-color-light-black;
    margin: 4px 0;
    border: none;
    color: $color-white-grey;
    outline: none;
    &_textarea {
      @include font_poppins_regular;
      outline: none;
      padding: .8em 1.5em;
      border-radius: 8px;
      background: $background-color-light-black;
      margin: 4px 0;
      font-size: 1.1em;
      resize: none;
      border: none;
      color: $color-white-grey;
      &::placeholder {
        font-weight: 600;
        color: $color-grey-placeholder;
        
        
      }
    }
    &::placeholder {
      font-weight: 600;
      color: $color-grey-placeholder;
    }
    &_label {
      align-self: flex-start;
    }
  }
}
.cursor-input{
  cursor: pointer;
}

