.search {
width: 1200px;
    &_container {
        @include font_poppins_regular;
        width: 720px;
        height: auto;
        background: $color-borders;
        box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        margin-left: 20%;
        margin-top: 5%;

        &_title {

            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 17px;
            color: $color-light-pink;
            margin-left: 5%;
            margin-top: 5%;

            &_result {
                font-weight: bold;
            }
        }

        &_categories {
            margin-top: 3%;
            margin-left: 5%;
            margin-bottom: 3%;
            width: auto;
            &_categorie {
                width: fit-content;
                min-width: 86px;
                height: 36px;
                background: $background-dark;
                border-radius: 30px;
                margin-right: 3%;
                padding: 2px;
                cursor: pointer;
                &_name {
                    color: rgba(134, 132, 157, 1);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    
                }

                &_dot {
                    margin: 3%;
                }

                &_count {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 17px;
                    color: rgba(76, 82, 112, 1);
                }
            }

            &_categorie:hover {
                background: rgba(23, 25, 35, 1);
            }
        }
    }
}

.categorie_active {
    background: rgba(23, 25, 35, 1) !important;
}

.big_text_width {
    width: 140px;
}

.item_width {
    width: 660px;
}