a {
  text-decoration: none;
  color: inherit;
}
.container_wallets {
  margin: 20px;
  border-radius: 10px;
  width: 300px;
  padding-bottom: 5px;
}

.wallets {
  margin: 45px;

  &_header {
    width: 300px;
    height: 48px;
    border-radius: 8px;
    background: rgba(25, 27, 38, 0.5);
    align-items: center;

    &_btn {
      margin-right: 4%;
      cursor: pointer;
    }

    &_amount {
      width: 140px;
      height: 41px;
      border-radius: 10px;
      //background: #9b8eff;
      background: #34374d;
      
      &_item {
        @include font_poppins_regular;
        font-size: 0.825rem;
      color: #cdcccd;
      font-weight: 700;
      line-height: 15px;
      //margin-left: 20px;
      align-items: center;
      /* text-align: center; */
      display: flex;
      }
      &_code {
        width :34px;
      }
    }
  }

  &_name {
    @include font_poppins_regular;
    font-size: $font-legend-info;
    color: $color-text-wallets;
    font-weight: 700;
    line-height: 15px;
    margin-left: 20px;
  }

  &_rewards {
    width: 300px;
    height: 48px;
    border-radius: 8px;
    background: #34374d;
    align-items: center;
    margin-top: 21px;
  }

  &_content {
    @include font_poppins_regular;
    cursor: pointer;
    margin-top: 2%;
    &_name {
      font-size: $font-body-light;
      //color: $color-blue;
      color: $color-text-wallets;
      margin-left: 15px;
      height: 16px;
      width: 90px;
      text-align: left;
      margin-top: 7px;
    }

    &_quantity {
      font-size: $font-body-3;
      color: $color-cyan;
      height: 16px;
      width: 70px;
      font-weight: 600;
      text-align: right;
    }

    &_money {
      font-weight: 600;
      font-size: 12px;
      color: $color-cyan;
      margin-left: 10px;
    }

    &_bottom {
    }
    &_convert {
      margin-top: -14px;
      font-size: $font-legend-info;
      text-align: right;
      width: 100%;
      margin-left: -3px;
      color: $color-text-wallets;
      font-weight: 600;
      margin-top: 4%;
      &_type {
        padding-left: 12px;
      }

      &_text {
        margin-right: 43%;
        margin-left: 4%;
      }

      &_img {
        margin-top: 2%;
      }
    }
    // &_percent {
    //   float: left;
    //   padding-left: 45px;
    // }
    &_buttons {
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 13px;
      color: $background-color-white;
      margin-top: 5%;
      margin-right: 2%;
      &_cashback {
        width: 92px;
        height: 26px;
        background: $background-color-light-dark;
        border-radius: 6px;
        cursor: pointer;
      }

      &_send {
        width: 54px;
        height: 26px;
        background: $background-color-light-dark;
        border-radius: 6px;
        cursor: pointer;
        margin-left: 2%;

        &_img {
          width: 25px;
          height: 25px;
          margin-left: -25%;
          margin-right: -10%;
        }
      }

      &_change {
        width: 68px;
        height: 26px;
        background: $background-color-light-dark;
        border-radius: 6px;
        cursor: pointer;
        margin-left: 2%;

        &_img {
          width: 25px;
          height: 25px;
          margin-left: -20%;
          margin-right: -5%;
        }
      }
    }
  }
}

// .red {
//   color: #FFB5B5;
// }
// .green {
//   color: #84F3B7;
// }

.wallets_more {
  text-align: center;

  margin-right: 10px;
  margin-top: 10%;
  &_text {
    @include font_poppins_regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-decoration: underline;
    color: $color-pure-purple;
    margin: 10px;
    cursor: pointer;
  }
}
