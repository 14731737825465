@import "../../../Style/_mixins";
@import "../../../Style/_variables.scss";

.Settings {
  margin: 100px 100px 50px 100px;
  flex: 1;

  .notif-tab {
    background: #2a2d3e;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    padding: 40px;

    display: flex;

    .faq_head {
      justify-content: start;
      gap: 2.5em;
      @include font_montserrat_regular;
      font-weight: bold;

      span {
        padding: 1em 1em 1em 0;
        color: $color-text-purple;
        cursor: pointer;

        a {
          color: inherit;
        }
      }

      .active {
        color: $color-lighter-purple;
        border-bottom: 3px solid $color-lighter-purple;
      }

      border-bottom: 1px solid $background-black-line;
    }
  }
  .page-title {
    margin-right: 100px;
    margin-bottom: 50px;
    p {
      @include font_poppins_regular;
      font-size: 1.5rem;
      color: $background-color-white;
      font-weight: 700;
      margin-top: 60px;
    }

    .register-header {
      @include font_poppins_regular;
      color: $color-text-purple;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      margin-top: 1%;
      display: flex;
      gap: 0.5em;
      a {
        color: $color-text-purple;
      }
      span {
        @include font_poppins_regular;
        align-self: flex-start;
        color: $color-text-purple;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        &:last-child {
          font-weight: bold;
        }
      }
    }
  }
}
