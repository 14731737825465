.user_container {
    width: 360px;
    height: 60px;
    border-radius: 10px;
    background: transparent;
    cursor: pointer;
    margin-bottom: 2%;
    margin-top: 1%;

    &_img {

    }

    &_name {
        @include font_poppins_regular;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $color-white-grey;
    }
}

.user_container:hover {
    background: rgba(24, 26, 36, 1);
}