.all-transactions {
    //width  :100%;
    &_container {
        margin-top: -65px;
        background: #2a2d3e;
        border-radius: 20px;
        //width: 1120px;
        width : 1310px;
        height : auto;
        //height: 100px;
        //margin: 33px;
        &_content {
            /*overflow-x: scroll;
            //scrollbar-color: #6969dd #cd1d1d;
            //scrollbar-width: thin;
            &::-webkit-scrollbar {
                width: 10px !important;
                height :10px;
                border: 1px solid red;
                border-radius: 100px;
                
            }
            &::-webkit-scrollbar-track {
                background-color: darkgrey;
                border-radius: 100px;
            }
            &::-webkit-scrollbar-thumb {
                //background-image: linear-gradient(180deg, rgb(33, 36, 50) 0%, rgb(216, 215, 225) 99%);
                background-image: linear-gradient(292.53deg, #9381FF 31.56%, #9381FF 97.92%);;
                box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
                border-radius: 100px;
            }*/


        &_tab {
            background: #212432; 
            border-radius: 10px;
            height: 52px;
            margin-top: 23px;
            //width: 96%;
            //background: red;
            margin-left: 2%;
            margin-right: 2%;
            margin-bottom: 33px;

            &_row {
                color: #d8d7e1;
                @include font_poppins_regular;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                margin-left: 1%;
                margin-right: 1%;
                padding-top: 17px;

                &_transac {
                    //width : 10.3%;
                    width : 7.3%;
                }
                &_date {
                    //width : 10%;
                    width : 7.3%;
                }
                &_side {
                    width : 5%;
                }
                &_price {
                    width : 10%;
                }
            }
        }

        &_elems {
            //width : 96%;
            //margin-top : 23px;
            margin-left: 2%;
            //margin-bottom: 23px;
            &_row {
                color: #d8d7e1;
                @include font_poppins_regular;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /*margin-left: 1%;
                margin-right: 1%;
                padding-top: 17px;*/

                &_transac {
                    //width: 8.8%;
                    //border: 1px solid red;
                    width : 7.3%;
                    margin-left: 0.9%;
                }
                &_date {
                    //width : 8.13%;
                    width : 7.13%;
                }
                &_side {
                    width : 4.8%;
                }
                &_price {
                    //width : 8.13%;
                    width: 9.43%;
                }
                &_status {
                    //width : 8.3%;
                    width : 4.13%;
                    margin-right: 8px;
                }
                &_cancel {
                    @include font_poppins_regular;
                    width: 90px;
                    height: 26px;
                    border-radius: 6px;
                    background: #212332;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 14px;
                    color: white;
                    cursor: pointer;
                    //margin-bottom: 2%;
                    align-self: flex-end;
                    margin-top: -2px;
                }
            }
        }
        
        &_line {
            background: #212432;;
            border-radius: 10px;
            width: 94%;
            height: 0.1px;
            margin: 33px;
        }
    }
    }
}

.transaction_scrool {
    background: linear-gradient(0deg, #363A52, #363A52), #363A52;
    border-radius: 2px;
    height: 1px;
    margin-top: 25px;
}
/*.scrool {
    width : 120vw; 
}*/