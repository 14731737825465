@import "../../../../../Style/_mixins.scss";
@import "../../../../../Style/variables";

.EditGroup {
  @include font_poppins_regular;
  color: $color-text-purple;

  .group-name-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin: 1em 0;

    label {
      font-weight: lighter;
    }

    input {
      padding: 1.3em 0.5em;
      border-radius: 0.5em;
      border: none;
      background-color: #212332;
      outline: none;

      &::placeholder {
        color: #86849d;
        font-weight: bold;
      }
    }
  }

  .group-title {
    margin-top: 50px;
    font-weight: lighter;
    display: flex;
    justify-content: space-between;

    span:last-child {
      cursor: pointer;
    }
  }

  .users-list {
    margin: 10px 0 50px 0;

    .user-item-container {
      padding: 1em 0.8em;
      background-color: #212332;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0.7em;

      .trash {
        background-image: url(../../../../../Assets/vectors/trash.svg);
        width: 2em;
        height: 2em;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }

      .user-item {
        display: flex;
        align-items: center;
        gap: 1em;

        &.profile-pic {
          background-image: url(../../../../../Assets/img/User/pp.png);
          width: 40px;
          height: 40px;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }

  .decision-buttons {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    gap: 0.8em;
  }
}
.title-groupItem {
  color: #86849d;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
}
.bs-trash {
  cursor: pointer;
  width: 45px;
}
