@import "../../../../../../Style/_mixins.scss";
@import "../../../../../../Style/_variables.scss";

.AddMember {
  @include font_poppins_regular;
  color: $color-text-purple;
  margin-top: 20px;

  .block-title {}
  .decision-buttons{
    margin: 30px 0;
    display: flex;
    justify-content: center;
    gap: .8em;
    .block-btn{
      padding: .8em 1.5em;
      border-radius: .5em;
      cursor: pointer;
    }
    .cancel{
      background-color: #1B1D29;

    }
    .confirm{
      background-color: #9997A7;
      &:hover{
        background-color: $color-pure-purple;
      }
    }
  }


}