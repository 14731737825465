.profile {
  margin-top: 90px;
  &_container {
    &_activity {
      width: 360px;
      height: 270px;
      margin: 8px;
      background: $color-grey-purple;
      border-radius: 20px;
      box-shadow: $shadow_account;

      &_title {
        margin-top: 30px;
        margin-left: 30px;
        @include font_poppins_regular;
        color: $color-text-purple;
        font-size: $font-body-2;
        text-transform: uppercase;
      }

      &_list {
        margin: 15px;
        max-height: 135px;
        min-height: 135px;

        &_empty {
          &_content {
            @include font_poppins_regular;
            color: $color-text-wallets;
            font-size: $font-legend-info;
            text-align: center;
            padding: 50px;
          }
        }

        &_row {
          margin: 5px;
          width: 300px;
          height: 30px;
          border-radius: 15px;
          &_type {
            margin: 5px;
            padding-right: 5px;
            padding-left: 10px;
            width: 20px;

            &_icon {
              height: 20px;
            }
          }

          &_stats {
            @include font_poppins_regular;
            color: $color-text-wallets;
            font-size: $font-legend-info;
            padding: 2px;

            &_date {
              font-style: italic;
              color: $color-text-wallets;
            }
          }
        }
        &_row:hover {
          background-color: $background-dark;
        }
      }
      &_more {
        @include font_poppins_regular;
        margin: 30px;
        text-align: center;
        font-size: $font-body-light;
        a {
          color: $color-light-purple2;
          text-decoration: underline;
        }
      }
    }
  }
}
