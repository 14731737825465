.market_puzzle {
    width: 210px;
    justify-content:unset;
    &_margin {
        //margin-top: 20%;
        margin-left: 5%;
    }

    &_list {
        width: 1010px;
    }

    &_img {
        margin-right: 20px;
    }
}

.search_puzzle {
    width: 360px;
    height: 60px;
    background: transparent;
    border-radius: 10px;
    margin-left: 5%;
    margin-bottom: 2%;
    margin-top: 1%;


    &_img {
        width: 45px;
        height: 40px;   
        margin-left: 1%;
        margin-right: 5%; 
    }
    
    &_content {
        @include font_poppins_regular;

        &_class {
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 18px;
            letter-spacing: 2px;
            text-transform: uppercase;
            opacity: 0.6;
            color: $color-white-grey;
        }

        &_title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: $color-white-grey;
        }
    }
}
.search_puzzle:hover {
    background: rgba(24, 26, 36, 1);
}
.link_height {
    height: 270px;
}

.margin_cover_profile {
    margin-top: -24%    ;
}

.margin_title_profile {
    margin-top: -19%;
}