.defaultPage {
  margin-top: 40px;
  margin-bottom: 10px;

  &_connect {
    margin-right: 20px;
    align-items: center;
    width: 350px;
    height: 80px;
    border: 1px solid #e3deff;
    box-sizing: border-box;
    filter: drop-shadow(0px 6px 11px rgba(0, 0, 0, 0.8));
    border-radius: 25px;
    margin-top: 50%;
    cursor: pointer;
    background-color: #372B44 ;

    &_text {
      @include font_poppins_regular;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 15px;
      color: #e3deff;
      //color: $color-light-pink;
    }
  }
}
